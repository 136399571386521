import Type from '../type'
import Processor from '../processor'
import genId from './genId'
import { Iteration } from '../interface'

/**
 * 二维map
 */
export class KeyMap {
  static map = new Map()
  private map = new Map()
  constructor() {}
  static getMajorKey(obj: Type.DefineObject): Map<any, any> {
    let mk = KeyMap.map.get(obj)
    if (!mk) {
      mk = new Map()
      KeyMap.map.set(obj, mk)
    }
    return mk
  }
  static set(obj: { [props: string]: any }, key: any, dVal: any = true) {
    const mk = KeyMap.getMajorKey(obj)
    mk.set(key, dVal)
  }
  static get(obj: { [props: string]: any }, key: any) {
    const mk = KeyMap.getMajorKey(obj)
    return mk.get(key)
  }
  static del(obj: { [props: string]: any }, key: any) {
    const mk = KeyMap.getMajorKey(obj)
    mk.delete(key)
  }
  static delMajorKey(mKey: any) {
    KeyMap.map.delete(mKey)
  }
  static each(
    obj: { [props: string]: any },
    fn: (arg0: any, arg1: any, arg2: Map<any, any>) => void
  ) {
    if (!(typeof fn === 'function')) return
    const mk = KeyMap.getMajorKey(obj)
    mk.forEach((value, key, map) => {
      fn(value, key, map)
    })
  }
  static toArray(obj: { [props: string]: any }) {
    const mk = KeyMap.getMajorKey(obj)
    const arr: any = []
    mk.forEach((value) => {
      arr.push(value)
    })
    return arr
  }
  getMajorKey(obj: any): Map<any, any> {
    let mk = this.map.get(obj)
    if (!mk) {
      mk = new Map()
      this.map.set(obj, mk)
    }
    return mk
  }
  set(obj: any, key: any, dVal: any = true) {
    const mk = this.getMajorKey(obj)
    mk.set(key, dVal)
  }
  get(obj: any, key: any) {
    const mk = this.getMajorKey(obj)
    return mk.get(key)
  }
  del(obj: any, key: any) {
    const mk = this.getMajorKey(obj)
    mk.delete(key)
  }
  delMajorKey(mKey: any) {
    this.map.delete(mKey)
  }
  each(obj: any, fn: (arg0: any, arg1: any, arg2: Map<any, any>) => void) {
    if (!(typeof fn === 'function')) return
    const mk = this.getMajorKey(obj)
    mk.forEach((value, key, map) => {
      fn(value, key, map)
    })
  }
  toArray(obj: any) {
    const mk = this.getMajorKey(obj)
    const arr: any = []
    mk.forEach((value) => {
      arr.push(value)
    })
    return arr
  }
}

const STOP = 'STOP'
const PROTECTNEVER = 'protectNever'

/**
 * 去除重复值
 */
function unique<T>(data: T[], callback = (i: T) => i as any): T[] {
  const hash = new Map()
  let arr: T[] = []
  Knife.each(Knife.protectNever(data), (i) => {
    const result = callback(i)
    const include = hash.get(result)
    if (include) {
      return
    }
    hash.set(result, result)
    arr.push(i)
    return
  })
  return arr
}

/**
 * 浅比较变化的key
 */
function diff<F extends Type.Iteration, S extends F>(
  first: F,
  second: S
): DiffType[number][] {
  //只能对象比较对象,数组比较数组
  //@ts-ignore
  return Dataman.maps(first, second, (items, keys) => {
    const [firstItem, secondItem] = items
    const [firstKeys, secondKeys] = keys
    if (firstKeys === secondKeys && !Object.is(firstItem, secondItem)) {
      return { type: 'update', value: secondItem, key: secondKeys }
    }
    //插入新节点
    if (firstKeys !== secondKeys && Type.isNever(firstKeys)) {
      return { type: 'add', value: secondItem, key: secondKeys }
    }
    //删除节点
    if (firstKeys !== secondKeys && Type.isNever(secondKeys)) {
      return { type: 'remove', value: firstItem, key: firstKeys }
    }

    if (Type.onlyOne(null, keys)) {
      throw new Error('status key is null!')
    }

    if (firstKeys !== secondKeys) {
      throw new Error('unknown error check status keys')
    }
  })
}
type DiffType = [
  { type: 'update'; key: string; value: any },
  { type: 'remove'; key: string; value: any },
  { type: 'add'; key: string; value: any }
]

/**
 * 创建一个num长度得数组
 */
function newArray<R>(num: number, callback: (index: number) => R): R[] {
  const arr: R[] = []
  for (let index = 0; index < num; index++) {
    arr.push(callback(index))
  }
  return arr
}
/**
 * 该函数让Dataman在循环中中断
 * */
function stop<T extends Type.DefineObject | any[]>(value?: T) {
  return Processor.mark({}, STOP, value) as { [STOP]: T }
}
/**
 * 获取stop方法的返回值
 * @example
 * 参考map方法
 */
function getStopTarget(value: Type.DefineObject | any[]) {
  return Processor.employMark(value, STOP)
}
/**
 * 在map遍历的时候保护null|undefined不被自动过滤
 */
function protectNever<T extends any[] | Type.DefineObject>(arr: T) {
  return Processor.mark(Dataman.assign(arr), PROTECTNEVER, PROTECTNEVER)
}

/**
 * 安全的获取键值
 * @param { function } callback
 * @param defaultVal 默认值
 * @example
 * var man={ home:{area:100}, name:'xiaoming' }
 * get(()=>man.name) // 'xiaoming'
 * get(()=>man.age,18) // 18
 * get(()=>man.home.location,'china') //china
 * get(()=>man.home.area,0) //100
 */
//function get<C>(...arg: any[]): C
function get<C, D = C>(callback: () => C, defaultVal?: D, showError = true): C {
  try {
    return callback()
  } catch (error: any) {
    if (showError) {
      //报错用于调式
      // setTimeout(() => {
      //   throw new Error(error)
      // })
      console.warn(error)
    }
    return defaultVal as unknown as C
  }
}

/**
 * 安全的设置键值或者执行一组操作
 * @param { function } callback
 * @param { function } errCallback 值设置失败时的回调函数
 * @returns { boolean } 值设置成功返回true,否则false
 * @example
 * var data=[]
 * set(()=> data[0].number=1) // false
 * set(()=> data[0]={}) // true
 * set(()=> data[0].number=1) // true
 */
function set<
  C extends Type.DefineFunc,
  D extends (errorCallback: () => void) => any
>(callback: C, errCallback?: D) {
  try {
    callback()
    return true
  } catch (error: any) {
    errCallback?.(() => {
      setTimeout(() => {
        throw new Error(error)
      })
    })

    return false
  }
}

/**
 * 清理所有空值包括''
 * @param strict true时清理所有空值 , false时只清理 null和 undefind
 * */
function clearjunk<T extends Type.Iteration>(_data: T, strict = true) {
  let data: any = _data
  if (Type.isEmpty(data)) return data
  Object.keys(data).forEach((key) => {
    if (strict) {
      if (Type.isEmpty(data[key])) {
        delete data[key]
        return
      }
    } else {
      if (strict === undefined) {
        if (data[key] === undefined) {
          delete data[key]
          return
        }
      }
      if (Type.isVoid(data[key])) {
        delete data[key]
        return
      }
    }
  })
  return data as T
}

/**
 *
 * 对象类型可迭代
 * @param data object | array
 * @param fn callback
 * @returns {boolean} false代表循环被 stop方法中断 , true代表正常循环
 * @des 使用 Dataman.stop() 可以在回调函数中终止当前循环
 * */
function each<T extends Type.BaseType>(
  data: T,
  fn: (...arg: unknown[]) => any
): boolean
function each<T extends any[]>(
  data: T,
  fn: (value: T[number], key: keyof T, index: number, source: T) => any
): boolean
function each<T extends object>(
  data: T,
  fn: (value: T[keyof T], key: keyof T, index: number, source: T) => any
): boolean
function each(data: any, fn: Function) {
  if (Type.isEmpty(data)) return true
  const keys: any = Object.keys(data)
  let i = 0
  for (const key of keys) {
    const result = fn(data[key], key, i, data)
    //如果有停止标志符号 那么退出循环
    if (Processor.employMark(result, STOP)) {
      return false
    }
    i++
  }
  return true
}
/**
 * 对象类型可迭代
 * 返回值为 never类型自动过滤
 * @example
 * Dataman.map(1|null|string) //[]
 * Dataman.map({a:1,b:2},(value,key)=>key) //[a,b]
 * Dataman.map({a:1,b:2}) //[1,2]
 * @example 使用stop方法及时退出循环
 *
 * const val = Dataman.map([1,2,3],()=> Dataman.stop('stop')) // []
 * Dataman.getStopTarget(val) // 'stop'
 * const val2 = Dataman.map([1,2,3],(n)=> n>2 ? Dataman.stop({target:n}):n ) // [1,2]
 * Dataman.getStopTarget(val2) // { target:3 }
 *
 * @example 使用protectNever方法禁止过滤never值
 *
 *  Dataman.map([1,null,undefined,4]) // [1,4]
 *  Dataman.map(Dataman.protectNever([1,null,undefined,4])) // [1,null,undefined,4]
 * */
function map<R = any, T extends string = string>(data: T, fn?: any): R[]
function map<R = any, T extends Type.Uniteration = Type.Uniteration>(
  data: T,
  fn?: any
): R[]
function map<R = any, T extends readonly any[] = any[]>(
  data: T,
  fn?: (value: T[number], key: string, index: number, source: T) => R
): Type.OmitNever<R>[]
function map<R = any, T extends Type.DefineObject = Type.DefineObject>(
  data: T,
  fn?: (value: T[keyof T], key: keyof T, index: number, source: T) => R
): Type.OmitNever<R>[]
function map(
  data: object,
  //@ts-expect-error
  fn = (data: any, key: string, index: number, source: any) => data
) {
  if (Type.isEmpty(data)) return []

  const array = [] as any[]

  const isProtected = Processor.employMark(data, PROTECTNEVER) === PROTECTNEVER

  const isEach = Dataman.each(data, (value, key, index, source) => {
    const val = fn(value, key, index, source)

    if (!Type.isNever(val) || isProtected) {
      array.push(val)
    }
    return val
  })

  //如果当前循环被中途中断
  if (isEach === false) {
    const target = array[array.length - 1]
    const newArray = array.slice(0, array.length - 1)
    Processor.mark(newArray, STOP, target)
    return newArray
  }
  return array
}

function filter<T extends any>(
  data: T[] | Type.DefineObject,
  fn: (data: T, key: any, index: any) => boolean
): T[] {
  return Dataman.map(data as any[]).filter(fn)
}

/**
   * 对多个数组或者对象同时遍历
   * @param params 最后一个参数应该为callback
   * @example
   *
     maps(['长沙','火车票'],['北京','飞机票'],['莫斯科','出租车'],(items,keys,index ) => {
     const [first,second,third] = items //
     return first + '-' + second  + '-' + third
     })
     // ['长沙-北京-莫斯科','火车票-飞机票-出租车']
   */
function maps(...params: Iteration[][]) {
  const dataArray: Type.Iteration[][] = params.slice(0, -1)
  const callback: Type.DefineFunc<any, [any[], string[], string]> = params[
    params.length - 1
  ] as any

  if (!Type.isFunc(callback)) {
    //最后一个参数不为callback抛出错误
    throw new Error('last params is not function')
  }
  //合并所有可迭代对象的key
  const keys: string[] = [
    ...new Set(
      //@ts-expect-error
      Dataman.map(dataArray, (item) => Dataman.map(item, (v, key) => key)).flat(
        1
      )
    )
  ]
  // 防止map自动过滤空值
  const protectedData = Dataman.protectNever(dataArray)

  return Dataman.map(keys, (key, index) => {
    const values = Dataman.map(protectedData, (data) => data[key])
    const keys = Dataman.map(protectedData, (data) =>
      Type.hasKey(data, key) ? key : null
    )
    return callback(values, keys as string[], index)
  })
}
/**
 * 可自动数字求和, 数组合并,对象合并
 * */
function reduce<
  T extends readonly any[],
  R extends object | readonly any[] | string | number
>(
  data: T,
  callback: (
    preVal: R,
    currentVal: T[number],
    key: string,
    index: number,
    source: T
  ) => R | void,
  initialValue: R
): R
function reduce<
  T extends Type.DefineObject,
  R extends object | readonly any[] | string | number
>(
  data: T,
  callback: (
    preVal: R,
    currentVal: T[keyof T],
    key: keyof T,
    index: number,
    source: T
  ) => R | void,
  initialValue: R
): R
function reduce<T>(
  data: any,
  callback: (
    preVal: any,
    currentVal: any,
    key: any,
    index: number,
    source: T
  ) => T | void,
  initialValue: any
): T
function reduce(
  data: { [x: string]: any },
  fn: (arg0: any, arg1: any, arg2: any, arg3: any, arg4: any) => any,
  initialValue: any
) {
  if (Type.isEmpty(data)) return initialValue
  return Object.keys(data).reduce(
    (current: any, key: string, nextIndex: number) => {
      const val = fn(current, data[key], key, nextIndex, data)
      if (Type.isObject(val)) {
        current = { ...current, ...val }
        return current
      }
      if (Type.isArray(val)) {
        current = [...current, ...val]
        return current
      }

      if (Type.isNumber(val)) {
        return (current = current + Type.number(val))
      }

      if (Type.isString(val)) {
        return (current = current + val)
      }
      if (val === undefined || val === null) {
        return current
      }
      return val
    },
    initialValue
  )
}
/**
 * 解析json,异常时返回空键值对
 * */
function parseJSON<T = any>(value: string | any, defaultVal = {}): T | {} {
  try {
    const str = value.replace(/\s*/g, '')
    const m = JSON.parse(str)
    return m
  } catch (error) {
    return defaultVal as T
  }
}

/**
 * 序列化数据,异常时返回默认值
 * */
function stringify(value: any, defaultVal = ''): string {
  try {
    const m = JSON.stringify(value)
    return m
  } catch (error) {
    return defaultVal
  }
}

/**
 * 异步迭代
 * */
async function asyncIterate(
  arr: any[],
  fn: (value: any, key: any, arr: any) => Promise<any> | void
) {
  //  let index = 0
  let bag = [] as any[]
  const iterateData = Dataman.iterate(arr)
  for (const val of iterateData) {
    const { value, key } = val
    const reuslt = await fn(value, key, arr)
    bag.push(reuslt)
    // index++
  }
  return bag
}

/**
 * 让对象可迭代
 * */
function iterate(obj: { [x: string]: any }) {
  if (Type.isArray(obj) || Type.isObject(obj)) {
    return Object.keys(obj).map((key) => ({ value: obj[key], key }))
  }
  return []
}
/**
 * 检测值再某个范围内
 * */
function inRange<T extends number>(
  num: T,
  range = [0, 0] as readonly [number, number]
) {
  const matchNum = Type.number(num)
  let [min = 0, max = 0] = range
  min = Type.number(min)
  max = Type.number(max)
  if (matchNum < min || matchNum > max) return false
  return true
}

/**
 * 从对象或者数组中把指定 key过滤掉
 * @param obj array | object
 * @param fields 匹配规则
 * @example
 * omit({key:1,name:2},['key']) //{ name:2 }
 * @example 剔除查找到的第一条数据
 * omit([{code:'name'},{code:'age'},{code:'name'}],[{code:'name'}]) // [{code:'age'},{code,name}]
 * @example 剔除所有符合规则的数据
 * omit([{code:'name'},{code:'age'}],[{code:'name'}]) // [{code:'age'}]
 * @example 使用多条匹配规则
 * omit([{code:'name'},{code:'age'}],[ {code:'name'},{code:'age'} ]) // []
 *
 * @todo 增加根据值类型过滤
 * */
function omit<T extends Type.DefineObject[]>(
  obj: T,
  callback: Type.DefineFunc<boolean>
): T
function omit<T extends Type.DefineObject[]>(
  obj: T,
  fields: Type.DefineObject[]
): T
function omit<T extends Type.DefineObject[]>(
  obj: T,
  fields: Type.DefineObject
): T
function omit<T extends Type.DefineObject, K extends keyof T>(
  obj: T,
  fields: K | K[]
): T
//function omit<T extends Type.DefineObject>(obj: T, fields: string | string[]): T
function omit<T extends Type.DefineObject | Type.DefineObject[]>(
  obj: T,
  fields: any[] | Type.DefineFunc<boolean> | Type.DefineObject
) {
  if (Type.isEmpty(obj)) return obj
  //omit目标为数组时
  if (Type.isArray(obj)) {
    // {code:'name'}[] 过滤规则映射
    const matchs = Dataman.map(Type.array(fields), (field: Type.DefineObject) =>
      Dataman.reduce<{ key: string; val: any }[]>(
        field,
        //@ts-expect-error
        (p, c, k) => [{ key: k, val: c }],
        []
      )
    )
    const callback: any = fields
    //检查根据回调函数过滤或者匹配规则过滤
    const filterCallback: Type.DefineFunc<boolean> = Type.isFunc(fields)
      ? callback
      : (i) => matchs.some((match) => match.every((m) => i[m.key] === m.val))

    if (Type.isObject(fields)) {
      //查找符合条件的第一条 return => object
      const firstData = Type.nullCoalesce(
        obj.find((i) => filterCallback(i)),
        {}
      )
      return obj.filter((i) => i !== firstData)
    }
    //过滤符合条件的所有 return => object[]
    return obj.filter((i) => !filterCallback(i))
  }
  const omitObj = new Map(Object.entries(obj))
  Type.array(fields as []).forEach((k: string) => omitObj.delete(k))
  return Object.fromEntries(omitObj)
}

function deleteKey<T extends Type.DefineObject, K extends keyof T>(
  target: T,
  key: K | K[]
) {
  const keys = Type.array<string[]>(key)
  const omitObj = new Map(Object.entries(target))
  keys.forEach((k) => omitObj.delete(k))
  return Object.fromEntries(omitObj) as Omit<T, K>
}
/**
 * 从对象或者数组中根据 key 挑选指定元素
 * @param obj array | object
 * @param fields 查找字段
 * @example
 * pick({},['key'])
 * pick([{code:'name'},{code:'age'}],[{code:'name'}]) // [{code:'name'}]
 * pick([{code:'name',sex:2,age:18 },{code:'name',sex:1 }],[{code:'name',sex:2}]) // [{code:'name',sex:2,age:18 }] 第二个参数为对象只查找第一条
 *
 * @todo 增加根据值类型查找
 * */
function pick<T extends Type.DefineObject[]>(
  obj: T,
  callback: Type.DefineFunc<boolean>
): T
function pick<T extends Type.DefineObject>(
  obj: T[],
  fields: Type.DefineObject[]
): T[]
function pick<T extends Type.DefineObject[]>(
  obj: T,
  fields: Type.DefineObject
): T[number]
function pick<T extends Type.DefineObject>(obj: T, fields: string | string[]): T
function pick<T extends Type.DefineObject>(
  obj: T,
  callback: Type.DefineFunc<boolean>
): T
function pick(obj: any[], fields: any) {
  if (Type.isEmpty(obj)) return obj
  //{code:'name'}[]
  const matchs = Dataman.map(Type.array(fields), (field: Type.DefineObject) =>
    Dataman.reduce<{ key: string; val: any }[]>(
      field,
      //@ts-expect-error
      (p, c, k) => [{ key: k, val: c }],
      []
    )
  )
  const callback = fields
  //检查根据回调函数过滤或者匹配规则过滤
  const filterCallback: Type.DefineFunc<boolean> = Type.isFunc(fields)
    ? callback
    : (i) =>
        matchs.some((match) =>
          match.every(
            (m: { key: string | number; val: any }) => i[m.key] === m.val
          )
        )

  //数组处理
  if (Type.isArray(obj)) {
    //只查找符合条件的第一条
    if (Type.isObject(fields)) {
      return Type.nullCoalesce(obj.find(filterCallback), {})
    }
    //obj.filter(every+some)
    return obj.filter(filterCallback)
  }
  if (Type.isFunc(fields)) {
    return Dataman.reduce(
      obj,
      //@ts-expect-error
      (_, current, key: string) =>
        filterCallback(obj[key]) ? { [key]: obj[key] } : null,
      {}
    )
  }
  //对象处理
  return Dataman.reduce(
    Type.array(fields),
    //@ts-expect-error
    (current, key: string) => ({ [key]: obj[key] }),
    {}
  )
}
/**
 * 通过键值对数组的键值查找元素
 * @param data
 * @param { object } fields 查找单个总是返回第一次查找到的元素
 * @param { object[] } fields 查找多个并总是返回一组元素
 * @param defaultVal 如果没有查找到返回的默认值
 * @example
 * // {code:'name',sex:1,age:18}
 * find([{code:'name',sex:1,age:18},{code:'age'}],{code:'name',sex:'1'})
 * // [{code:'name',sex:1},{code:'name',sex:2}]
 * find([{code:'name',sex:1},{code:'name',sex:2},{code:'age'}], [{code:'name'}] )
 * // [{code:'age',index:1},{code:'age',index:2},{code:'city'}]
 * find([{code:'name'},{code:'age',index:1},{code:'age',index:2},{code:'city'}], [{code:'age'},{code:'city'}] )
 *
 */

function find<
  T extends Type.DefineObject[],
  F extends { [P in keyof T[number]]: T[number][P] },
  D = undefined
>(
  data: T,
  fields: Partial<F>,
  defaultVal?: D
): D extends undefined ? T[number] | D : T[number]

function find<T extends Type.DefineObject[], F extends Type.DefineObject[]>(
  data: T,
  fields: F,
  defaultVal?: any
): Type.DefineObject<any, F[number]>[]

function find(data: any[], fields: { [props: string]: any }, defaultVal?: any) {
  if (Type.isArray(fields)) {
    return Dataman.pick(data, fields)
  }
  const matchs = Dataman.reduce<{ key: string; val: any }[]>(
    fields,
    //@ts-expect-error
    (p, c, k) => [{ key: k, val: c }],
    []
  )

  return Type.nullCoalesce(
    map(data).find((i) => matchs.every((match) => i[match.key] === match.val)),
    defaultVal
  )
}
/**
 * 通过键值对数组的键值查找元素下标
 * @param data
 * @param { object } fields 查找单个总是返回第一次查找到的元素下标
 * //param { object[] } fields 查找多个并总是返回一组元素下标
 * @param defaultVal 如果没有查找到返回的默认值 默认false
 * @example
 * // {code:'name',sex:1,age:18}
 * findIndex([{code:'name',sex:1,age:18},{code:'age'}],{code:'name',sex:'1'}) // 0
 * // [{code:'name',sex:1},{code:'name',sex:2}]
 * //find([{code:'name',sex:1},{code:'name',sex:2},{code:'age'}], [{code:'name'}] )
 * // [{code:'age',index:1},{code:'age',index:2},{code:'city'}]
 * //find([{code:'name'},{code:'age',index:1},{code:'age',index:2},{code:'city'}], [{code:'age'},{code:'city'}] )
 * @todo 可能增加查找下标集合
 */
//function findIndex<T extends Type.DefineObject[], F extends Type.DefineObject[]>(data: T, fields: F, defaultVal?): number[];
function findIndex<
  T extends Type.DefineObject[],
  F extends Type.DefineObject,
  D
>(data: T, fields: F, defaultVal?: D): number | D
function findIndex(data: any[], fields: any, defaultVal = false) {
  // if (Type.isArray(fields)) {
  //   return Dataman.pick(data, fields);
  // }
  const matchs = Dataman.reduce<{ key: string; val: any }[]>(
    fields,
    //@ts-expect-error
    (p, c, k) => [{ key: k, val: c }],
    []
  )
  const index = data.findIndex((i) =>
    matchs.every((match) => i[match.key] === match.val)
  )
  return index > -1 ? index : defaultVal
}
/**
 * 根据数组中的字段查找对应的值
 * @param {array} _arg 包含键值对的数组
 * @param {string[]} dependString  根据元素中的指定字段查找元素
 * @example
 * const fv = findByArray([{code:'xiaoming'}],['code']) //根据code查找数组元素
 * fv.set({xiaoming: {age:18,sex:'man'} })  // 给code键值为’xiaoming‘的元素设置值
 * fv.get('xiaoming') //  {code:'xiaoming', age:18,sex:'man'}
 *
 */
function findByArray<T extends Type.DefineObject[]>(
  _arg: T,
  dependString: string | string[]
) {
  //
  // type Returns = T & { set: typeof set; get: typeof get };

  let arg = Dataman.copy(_arg, 0)

  const dependStrings: string[] = Type.array(dependString) as string[]

  /**
   * 检查键值对元素中键名匹配的元素索引
   * @returns number
   */
  const getIndex = (findkey: string) => {
    return arg.findIndex((i) => {
      const isOwn = dependStrings.some((k) => i[k] === findkey)
      return isOwn
    })
  }
  /**
   * 检查键值对元素中键名匹配的元素
   * @returns boolean
   */
  const getVal = (findkey: string) =>
    arg.find((i) => {
      const isOwn = dependStrings.some((k) => {
        //key会被抓换成字符串, 判断数组左边界处理
        if (Type.isNumber(i[k])) {
          return String(i[k]) === findkey
        }
        return i[k] === findkey
      })
      return isOwn
    })

  /**
   * 依赖索引声明
   * */
  arg.forEach((i) => {
    dependStrings.forEach((dk) => {
      if (Type.isVoid(i[dk])) return
      //设置key名与数组对应元素关联
      arg[i[dk]] = i
      Processor.setPropOutLoop(arg, i[dk])
    })
  })

  /**
   * 转换成存数组
   * @returns
   */
  // const toArray = () => {
  //   return lodash.merge([], arg) as T;
  // };

  /**
   * 通过元素字段设置元素
   * @param fileds
   * @returns string[]  成功赋值返回对应字段的key
   */
  const set = (fileds: Type.DefineObject<Type.DefineObject>) => {
    let keys: string[] = []
    Dataman.each(fileds, (value, key: string) => {
      const row = getVal(key)
      if (Type.isNever(row)) {
        return
      }
      keys.push(key)
      //将最新value 复制到当前row
      Object.assign(row, Dataman.merge(row, value))
      //通过merge合并row
    })
    return keys
  }

  /**
   * 通过元素键名查找元素
   * @param key string
   * @returns
   */
  function get<T = any, D = {}>(key: string): T | D
  function get<T = any, D = {}>(keys: string[]): (T | D)[]
  function get(key: string | any[], defaultVal = {}) {
    if (Type.isArray(key)) {
      return Dataman.map(key, (k) => Type.nullCoalesce(getVal(k), defaultVal))
    }
    return Type.nullCoalesce(getVal(key), defaultVal)
  }
  const NOT = Processor.safeKey('NOT')

  /**
   * 将数组元素转换成键值对形式
   * @param keys string[]
   */
  function gets<T = Type.DefineObject>(
    keys: string[] = Dataman.map(
      eArg,
      (i) => i[dependStrings.find((k) => !Type.isNever(i[k])) || NOT]
    )
  ) {
    return Dataman.reduce(keys, (_, key) => ({ [key]: get(key) }), {}) as T
  }

  /**
   * 通过元素键名剔除数组
   * @param key
   */
  //const omit = (key: string | string[]) => {}

  const eArg = Processor.extend(arg as unknown as T, { set, get, gets })

  return eArg
}
/**
   * 根据元素中的字段设置assign对应元素的值
   * @param { array } arg 包含键值对的数组
   * @param { object } fields  根据元素中的指定字段查找元素
   * @param { array } value 为数组时查找不到元素会自行添加
   * @param { object } value 为键值对时查找到元素自动赋值
   * @example 根据字段浅拷贝
     //根据code字段查找数组元素并赋值 => [ { code:'xiaoming',age:18}, { code:'张三' } ]
     assignByArray( [ { code:'xiaoming'}, {code:'张三'} ], {code:'xiaoming'}, {age:18 } )
     //没有lisi元素,元素不变
     assignByArray( [ { code:'xiaoming'}, {code:'张三'} ], {code:'lisi'}, { age:18 } )
   * @example 根据字段浅拷贝或者添加
      // [{ code:'xiaoming'}, { code:'张三' }, { code:'lisi', age:18 } ]
      assignByArray( [ { code:'xiaoming'}, {code:'张三'} ], {code:'lisi'}, [{ age:18 }] )
   */
function assignByArray<
  T extends Type.DefineObject[],
  F extends { [P in keyof T[number]]: T[number][P] },
  V extends Type.DefineObject
>(arg: T, fields: Partial<F>, value: V) {
  const element = Dataman.find(arg, fields)
  if (Type.isNever(element)) {
    arg.push(Dataman.assign(fields, value))
    return arg
  } else {
    Object.assign(Type.object(element), value)
  }
  return arg
}
/**
 * 截取数字字符数组的长度默认从右边第一个开始
 * */
function cut(str: string, length = 1) {
  return str.substring(0, str.length - length)
}

function toStack(data: Type.Iteration) {
  let index = 0
  let deep = Infinity
  // let copyGroup: any[]
  const copyData = Dataman.assign(data)
  const iterGroup: Type.Iteration[] = [copyData]
  const statck = []

  while (iterGroup[index]) {
    //@ts-expect-error
    Dataman.map(iterGroup[index], (data, key: number, index, target: any) => {
      if (Type.isIter(data)) {
        target[key] = Dataman.assign(data)
        statck.push(target[key])
        iterGroup.push(target[key])
      }
    })

    index++
  }

  return statck
}
/**
 * 默认浅拷贝
 * 只拷贝对象和数组,函数直接返回
 * @param { true } deep    json深拷贝,
 * @param { number } deep 数字代表拷贝层数, 0=忽略层数限制
 * */
function copy<T>(val: T, deep?: boolean | number): T {
  if (!Type.isIter(val)) return val
  if (deep === true) {
    if (Type.isIter(val)) return JSON.parse(JSON.stringify(val))
    return val
  }

  if (typeof deep === 'number') {
    /*  1.浅拷贝 , 2. 遍历可迭代对象 3.可迭代对象放入数组,重复1*/
    let index = 0
    if (deep == 0) deep = Infinity
    // let copyGroup: any[]
    const copyData = Dataman.assign(val)
    const iterGroup: Type.Iteration[] = [copyData]
    while (iterGroup[index] && index < deep) {
      //@ts-expect-error
      Dataman.map(iterGroup[index], (data, key: number, index, target: any) => {
        if (Type.isIter(data)) {
          target[key] = Dataman.assign(data)
          iterGroup.push(target[key])
        }
      })

      index++
    }

    return copyData

    /* 重复1 */
  }

  return Dataman.assign(val)
}
/**
 * bfs 广度优先遍历
 * @param { iterate } data 迭代数据
 * @param { (currentStack: any[], data, path:string[], source, depth: number) } callback 迭代时回调
 * @param { number } deep 遍历深度
 * @callback param data 当前栈数据
 * @callback param path 当前栈数据的键名
 * @callback param source  当前栈的父级数据
 * @callback param depth 当前栈的层级深度
 * @example callback 描述; 以{a:{b:{c:'1'}}} 为列子
 *
 * 当读取到 a.b.c时
 *  data=>1; path=>'c'; source=>{ c:'1' } ; depth => 2
 *
 * @example
 *
 *   bfc(data,(stack,data,path,source)=>{
 *        if(Array.isArray(data)){
 *            stack.pushStack(data)
 *        }
 *   })
 *
 * */
function bfs(
  data: any,
  callback: (
    currentStack: { pushStack?: (data: Type.Iteration, path: string[]) => {} },
    data: any,
    path: string[],
    source: any,
    depth: number
  ) => void,
  deep = 0
) {
  if (!Type.isIter(data)) {
    throw new Error('data is not iterate')
  }
  /*  1.浅拷贝 , 2. 遍历可迭代对象 3.可迭代对象放入数组,重复1*/
  let index = 0
  if (deep == 0) deep = Infinity
  // let copyGroup: any[]
  const copyData = data
  const iterGroup = [copyData] as any[] & { pushStack: typeof pushStack }
  const stackPath = new Map()

  const getFatherKey = () => {
    const fatherPath: string[] = stackPath.get(iterGroup[index])
    return fatherPath
  }
  /**
   * 将一组可迭代数据压入栈
   * @param data
   */
  const pushStack = (data: Type.Iteration, path: string[]) => {
    // const fatherPath: string[] = getFatherKey();
    const _data = Dataman.assign(data)
    stackPath.set(_data, [...path])
    iterGroup.push(_data)
  }

  iterGroup.pushStack = pushStack

  stackPath.set(iterGroup[index], [])

  while (iterGroup[index] && index < deep) {
    //   target当前被迭代数据
    const fatherPath: string[] = getFatherKey()
    Dataman.each(iterGroup[index], (data, key: string, fatherSource) => {
      //@ts-expect-error
      callback(iterGroup, data, [...fatherPath, key], fatherSource, index)
    })

    index++
  }

  return iterGroup
}

/**
 * 对可迭代类型进行栈遍历
 */
function stackTraversal(controlData: Type.Iteration) {
  type Stack = ReturnType<typeof getDataStack>

  //通过keyindex获取目标数据
  const readData = (data: any, keyPath: string[]) => {
    const depsKey = keyPath

    let _data = data
    depsKey.forEach((i) => {
      _data = _data[i]
    })
    return _data
  }
  //通过keyindex设置目标数据
  const setData = (data: any, keyPath: string[], value: any) => {
    const depsKey = keyPath
    const zero = depsKey.pop() as string
    let _data = data
    depsKey.forEach((i) => {
      _data = _data[i]
    })
    _data[zero] = value
    //return _data;
  }
  /**
   * 树形结构转栈结构,并给与数据直接的层级关系
   * @returns stack : StackData []
   * @example StackData描述
   *
   * 比如通过对象 { a:{name:'1'} } 生成 a.name的stackData为
   * key => [a,name]
   * value => 1
   * source => { name:1 }
   * depth = > 1
   *  */
  const getDataStack = (
    data: Iteration,
    recordStackDataFromCallback?: Type.DefineFunc
  ) => {
    type StackData = {
      /**
       * 当前栈值
       */
      value
      /**
       * 父级数据的值,上一层的值
       */
      source: any
      /**
       * 当前栈的路径, 比如 [a,name,b]
       */
      key: string[]
      /**
       * 当前栈位于树结构的深度
       */
      depth: number
    }
    const newSource = data
    const statck = [] as StackData[]

    Dataman.bfs(
      newSource,
      (bfcStack, data, dataKeyPath: string[], source, depth) => {
        //将可迭代数据加入bfs队列
        if (Type.isIter(data)) {
          const hooksData =
            recordStackDataFromCallback?.(data, dataKeyPath) || data
          //将当前队列数据转入stack结构
          bfcStack.pushStack(hooksData, dataKeyPath)

          statck.push({ value: data, source, key: dataKeyPath, depth })
          return
        }

        //将当前队列数据转入stack结构
        statck.push({ value: data, source, key: dataKeyPath, depth })
      }
    )

    statck.unshift({ origin: data } as any)

    return statck
  }

  /**
   * 通过栈信息合并数据
   * source data assign to target
   * */
  const dataFitOfStatck = (sourceStack: Stack, callback: Type.DefineFunc) => {
    let n = 1
    /* 检查迭代是否越界 */
    while (n < sourceStack.length) {
      const { value: sourceVal, source, key: sourceKey } = sourceStack[n]
      callback(sourceKey, source)
      n++
    }
  }

  return {
    createStack(callback?: Type.DefineFunc<string[]>) {
      const stack = getDataStack(controlData, callback)
      return stack
    },
    traverse(stack: Stack, callback: Type.DefineFunc<any, [string[], any]>) {
      // const stack = getDataStack(controlData)
      dataFitOfStatck(stack, callback)
    },
    readData,
    setData
  }
}

/**
 * 数据模型匹配,如果目标数据不符合预期，返回模型数据结构
 * @param callback 数字和字符不符合预期时生成空字符串'',可用callback自定义数据结构
 */
function model<T>(
  data: any,
  modelData: T,
  callback?: (obj: {
    type: ReturnType<typeof Type.getType>
    path: string[]
    atomData: any
    atomModel: any
    parent: any
  }) => any
): T {
  if (!Type.isIter(modelData)) {
    throw new Error('Model need iterable types')
  }

  // if (Type.isEmpty(data) || !Type.isIter(data)) {
  // }

  const baseData = Type.assertType(copy(data, 0), modelData)
  const _modelData = modelData
  // _modelData = Dataman.copy(data, 0)

  const st = stackTraversal(modelData)

  const stack = st.createStack((data) => {
    //数组只参考第一元素的类型数据
    if (Type.isArray(data)) {
      return [data[0]]
    }
    return data
  })

  st.traverse(stack, (modelStackKey: string[], parent) => {
    const dataFromCurrentStack = st.readData(baseData, modelStackKey)

    const modelFromCurrentStack = st.readData(_modelData, modelStackKey)
    //判断两端数据类型是否一致
    const isSame =
      Type.getType(dataFromCurrentStack) === Type.getType(modelFromCurrentStack)

    const isIter = Type.isIter(modelFromCurrentStack)

    const convertData = (baseData) => {
      //父元素是数组 并且baseData是null或者undefined的情况
      //callback()

      if (callback) {
        const hookData = callback({
          type: Type.getType(modelFromCurrentStack, true),
          path: modelStackKey,
          atomData: dataFromCurrentStack,
          atomModel: modelFromCurrentStack,
          parent
        })
        st.setData(baseData, modelStackKey, hookData)
        return
      }

      if (
        Type.isType(modelFromCurrentStack, String) ||
        Type.isType(modelFromCurrentStack, Number)
      ) {
        st.setData(baseData, modelStackKey, '')
      } else {
        st.setData(baseData, modelStackKey, null)
      }
    }

    if (!isSame && isIter) {
      st.setData(
        baseData,
        modelStackKey,
        //断言成model类型
        Type.assertType(0, modelFromCurrentStack)
      )
      return
    }

    if (!isSame && !isIter) {
      // 数组不应该给默认值
      if (Type.isArray(parent) && Type.isVoid(baseData)) return
      //查找父元素是否为数组,对数组进行遍历检查赋值
      if (Type.isArray(parent)) {
        parent.map((node) => {
          convertData(node)
        })
      } else {
        convertData(baseData)
      }
    }
  })

  return baseData
}

/**
 * 对象合并,产生新对象,无副作用
 * 只合并对象和数组
 * @param {...arg} value
 * @returns new data
 * @example
 * //@todo 索引标记换成数组栈
 * users={ 'data': [{ 'user': 'barney' }, { 'user': 'fred' }]};
 * ages={ 'data': [{ 'age': 36 }, { 'age': 40 }] }
 * merge(users, ages); // => { 'data': [{ 'user': 'barney', 'age': 36 }, { 'user': 'fred', 'age': 40 }] }
 *
 * */
function merge<T extends any[]>(
  ..._arg: T
): Type.UnionToIntersection<T[number]> {
  const arg = Dataman.map(_arg)
  type Stack = ReturnType<typeof getDataStatck>
  //对象依赖关系查询主键
  // const keyIndex = 'keyIndex';
  //通过keyindex获取目标数据
  const readData = (data: any, keyPath: string[]) => {
    const depsKey = keyPath

    let _data = data
    depsKey.forEach((i) => {
      _data = _data[i]
    })
    return _data
  }
  //通过keyindex设置目标数据
  const setData = (data: any, keyPath: string[], value: any) => {
    const depsKey = keyPath
    const zero = depsKey.pop() as string
    let _data = data
    depsKey.forEach((i) => {
      _data = _data[i]
    })
    _data[zero] = value
    //return _data;
  }
  /**
   * 树形结构转栈结构,并给与数据直接的层级关系
   * @returns stack : StackData []
   * @example StackData描述
   *
   * 比如通过对象 { a:{name:'1'} } 生成 a.name的stackData为
   * key => [a,name]
   * value => 1
   * source => { name:1 }
   * depth = > 1
   *  */
  const getDataStatck = (data: any) => {
    type StackData = {
      /**
       * 当前栈值
       */
      value: any
      /**
       * 父级数据的值,上一层的值
       */
      source: any
      /**
       * 当前栈的路径, 比如 [a,name,b]
       */
      key: string[]
      /**
       * 当前栈位于树结构的深度
       */
      depth: number
    }
    const newSource = data
    const statck = [] as StackData[]

    Dataman.bfs(
      newSource,
      (bfcStack, data, dataKeyPath: string[], source, depth) => {
        //将可迭代数据加入bfs队列
        if (Type.isIter(data)) {
          //将当前队列数据转入stack结构
          bfcStack.pushStack(data, dataKeyPath)

          statck.push({ value: data, source, key: dataKeyPath, depth })
          return
        }

        //将当前队列数据转入stack结构
        statck.push({ value: data, source, key: dataKeyPath, depth })
      }
    )

    statck.unshift({ origin: data } as any)

    return statck
  }

  /**
   * 通过栈信息合并数据
   * source data assign to target
   * */
  const dataFitOfStatck = (target: any, sourceStack: Stack) => {
    let n = 1
    // @ts-ignore
    const sourceData = sourceStack[0]?.origin
    /* 检查迭代是否越界 */
    while (n < sourceStack.length) {
      const { value: sourceVal, source, key: sourceKey } = sourceStack[n]

      //可迭代类型覆盖(同类型) 刷新栈
      //可迭代类型替换(不同类型) 刷新栈
      const val = readData(sourceData, sourceKey)
      const tVal = readData(target, sourceKey)
      const isTypeSame = Type.getType(tVal) === Type.getType(val)
      const isIter = Type.isIter(val) || Type.isIter(tVal)
      n++

      //类型一样如果是引用类型不进行拷贝
      if (isTypeSame && isIter) {
        //
        continue
      }
      //类型不一样并且存在引用类型拷贝并覆写
      if (!isTypeSame && isIter) {
        setData(target, sourceKey, Dataman.copy(val, 0))
        continue
      }
      //类型不一样但没有引用类型覆写
      setData(target, sourceKey, val)
    }
  }

  const baseData = Dataman.copy(arg[0], 0)

  for (let index = 1; index < arg.length; index++) {
    const element = arg[index]
    const sourceStack = getDataStatck(element)
    dataFitOfStatck(baseData, sourceStack)
  }

  return baseData
}
/**
 * 浅拷贝,只针对数组和对象
 * */
function assign<T extends Type.Iteration, U extends readonly [...any]>(
  val: T,
  ...arg: U
): T & Type.UnionToIntersection<U[number]>
function assign<
  T extends Type.BaseType | Type.wrapperObject,
  U extends readonly [...any]
>(val: T, ...arg: U): T
function assign<T, U extends readonly [...any]>(val: T, ...arg: U) {
  if (Type.isArray(val)) {
    return Object.assign([], val, ...arg)
  }
  if (Type.isObject(val)) {
    return Object.assign({}, val, ...arg)
  }
  return val
}
/**
 * 链接数组或者字符串并排空
 * */
function concat<T extends any[] | string>(
  data: T,
  ...arg: any[]
): T extends string ? string : any[] {
  const _ = arg.filter((i) => !Type.isNever(i))
  return data?.concat(..._) as any
}

/**
 * 使用随机整数数n~m
 * */
function random(min = 0, max = 10) {
  max = max - min
  return parseInt(String(Math.random() * Number(max))) + min
}

/**
 * 使用随机实数n~m
 * */
function randomRx(min = 0, max = 10) {
  max = max - min
  return Number(String(Math.random() * Number(max))) + min
}
/**
 * 根据字符集拼接对象
 */
function joinObj<T>(
  obj: Type.Iteration,
  keys: (string | number)[],
  defualtVal?: any
): T | undefined {
  let data = obj
  try {
    for (const key of keys) {
      data = data[key] ?? defualtVal
    }
  } catch (error) {
    return
  }
  return data as T
}
/**
 * 根据字符集生成对象
 * @example
 * pathToObj([a,b,c]) // a:{b:{c:{}}}
 * pathToObj([a,b,c],2) // a:{b:{c:2}}
 */
function pathToObj<T>(keys: (string | number)[], lastValue = {}): T {
  const obj = [...keys].reverse().reduce((a, b, index) => {
    if (index === 0) {
      return { [b]: lastValue }
    }
    return { [b]: a }
  }, {})

  return obj as T
}

/**
 * 下划线转小驼峰
 */
function underlineToSmallCamelCase(str: string) {
  if (!Type.isString(str)) return str
  return str //@ts-expect-error
    .replace(/_([a-z])/g, function (match, letter) {
      return letter.toUpperCase()
    }) //@ts-expect-error
    .replace(/^([A-Z])/g, function (match, letter) {
      return letter.toLowerCase()
    })
}

/**
 *
 */
function camelCase<T extends Type.Iteration>(
  data: T
): Type.CamelCaseProperties<T> {
  if (!Type.isIter(data)) return data as Type.CamelCaseProperties<T>

  const newData = Type.isArray(data) ? [] : {}

  Knife.each(data, (value: any, key) => {
    const camelCasekey = underlineToSmallCamelCase(key as string)
    if (Type.isIter(value)) {
      newData[camelCasekey] = camelCase(value)
      return
    }
    newData[camelCasekey] = value
  })

  return newData as Type.CamelCaseProperties<T>
}

/**
 * 小驼峰转下划线
 */
function camelCaseToUnderline<T extends object>(data: T): T {
  if (!Type.isIter(data)) return data

  const newData = Type.isArray(data) ? [] : {}

  Knife.each(data, (value: any, key) => {
    const camelCasekey = camelToUnderscore(key)
    if (Type.isIter(value)) {
      newData[camelCasekey] = camelCaseToUnderline(value)
      return
    }
    newData[camelCasekey] = value
  })

  return newData as T
}

function camelToUnderscore(str) {
  return str.replace(/([A-Z])/g, '_$1').toLowerCase()
}

/**
* 生成随机字符串
// 生成一个长度为 8 的随机字符串
  const randomString = generateRandomString(8)
  console.log(randomString)
*/
function randomString(length: number) {
  let result = ''
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }

  return result
}

/**
 * 一些数据操作方法
 *
 * */
const Dataman = {
  toStack,
  deleteKey,
  camelCase,
  randomString,
  camelToUnderscore,
  camelCaseToUnderline,
  underlineToSmallCamelCase,
  bfs,
  asyncIterate,
  parseJSON,
  inRange,
  reduce,
  iterate,
  copy,
  omit,
  cut,
  assignByArray,
  findByArray,
  findIndex,
  find,
  pick,
  diff,
  unique,
  newArray,
  pathToObj,
  joinObj,
  genId,
  randomRx,
  random,
  concat,
  assign,
  merge,
  maps,
  filter,
  map,
  each,
  clearjunk,
  set,
  get,
  getStopTarget,
  protectNever,
  stop,
  stringify,
  model,
  stackTraversal
} as const

// export class Dataman {
//   static diff = diff
//   static unique = unique
//   static newArray = newArray
// }

export const Knife = Dataman

export default Dataman
