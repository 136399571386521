import thunk from 'redux-thunk'

import productsSlice from '@api/hernest/products/productsSlice'
import cartSlice from '@api/home/shopCart/cartSlice'
import { configureStore } from '@reduxjs/toolkit'

import homeReducer from '../pages/Home/homeSlice'
import appSlice from './slice/appSlice'
import loginSlice from './slice/loginSlice'
import pageDataSlice from './slice/pageDataSlice'
import searchInputSlice from './slice/searchInputSlice'
import { isDev } from '@utils'

const rootReducer = {
  app: appSlice,
  home: homeReducer,
  searchInput: searchInputSlice,
  products: productsSlice,
  shopcart: cartSlice,
  login: loginSlice,
  page: pageDataSlice
}

const getReduxStore = (defaultState: { [x: string]: any }) => {
  return configureStore({
    reducer: rootReducer,
    middleware: [thunk],
    devTools: isDev,
    preloadedState: defaultState
  })
}

const initialState = getReduxStore({}).getState

export type RootState = ReturnType<typeof initialState>

export default getReduxStore
