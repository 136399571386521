import React from 'react'

import { hydrateRoot, createRoot } from 'react-dom/client'

import App from '../App'
import getReduxStore from '../store'

console.info('process.env.SSR: ', process.env.SSR)

if (process.env.SSR === 'true') {
  let payloadData = {}
  let dehydratedState = null
  try {
    const context = (window as any)?.__sandwich__
    const dehydrated = (window as any)?.__sandwich_d__
    payloadData = context ? context : {}
    dehydratedState = dehydrated ? dehydrated : {}
  } catch (e) {
    console.log(e)
  }

  const store = getReduxStore(payloadData)
  hydrateRoot(
    document.getElementById('root')!,
    <App
      store={store}
      isServer={false}
      preloadedState={payloadData}
      dehydratedState={dehydratedState}
      helmetContext={{}}
    />
  )
} else {
  const store = getReduxStore({})
  createRoot(document.getElementById('root')!).render(
    <App
      store={store}
      isServer={false}
      preloadedState={{}}
      helmetContext={{}}
    />
  )
}
