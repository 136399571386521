import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { request } from '@utils/request'

export interface AppState {
  isLogin: boolean
  userInfo: any
  topAd: any[]
  menu: any[]
  footerLink: any[]
  yotpoAppKey: string
  categoryKey?: number
  newUserCouponPackAmount: string // 新人券包总额
  turntable: any // 转盘抽奖数据
  logoPath: string
  footerPath: string
  backgroundPath: string
  subscriptionText
  couponInfo
  currentAddCartCount?: number // 当前加购数量计算
  currentAddCartSkuIds?: string[] // 当前加购的 SkuIds
}

const initialState: AppState = {
  isLogin: false,
  userInfo: {},
  topAd: [],
  menu: [],
  footerLink: [],
  yotpoAppKey: '',
  categoryKey: 0,
  newUserCouponPackAmount: '',
  turntable: {},
  logoPath: '',
  footerPath: '',
  backgroundPath: '',
  subscriptionText: {},
  couponInfo: {}
}

export const AppSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    updateUserInfo: (state, action: PayloadAction<any>) => {
      state.userInfo = action.payload
      state.isLogin = !!action.payload?.customersId
    },
    updateCategoryKey: (state, action: PayloadAction<any>) => {
      state.categoryKey = action.payload
    },
    updateCouponInfo(state, action: PayloadAction<any>) {
      state.couponInfo = action.payload
    },
    updateSubscriptionText(state, action: PayloadAction<any>) {
      state.subscriptionText = action.payload
      // setItem('subscriptionText', action.payload)
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCommonData.fulfilled, (state, action) => {
      const {
        top,
        nav,
        bottom,
        yotpo,
        coupon,
        turntable,
        logoPath,
        footerPath,
        backgroundPath
      } = action.payload.data
      state.topAd = top || []
      state.menu = nav || []
      state.footerLink = bottom || []
      state.yotpoAppKey = yotpo?.appKey || ''
      state.newUserCouponPackAmount = coupon?.newUserCouponPackAmount
      state.turntable = turntable || {}
      state.logoPath = logoPath
      state.footerPath = footerPath
      state.backgroundPath = backgroundPath
    })
  }
})

export const { updateUserInfo, updateCategoryKey, updateCouponInfo } =
  AppSlice.actions

// 用于检查用户身份信息
export const getCustomerInfo = () => {
  return request
    .get('/customers/getCustomerInfo')
    .then((res) => {
      return res
    })
    .catch((err) => {
      return err
    })
}

interface CommonParams {
  terminal?: string
}

/**
 * @description: 获取公共数据（顶部广告/导航菜单/页脚链接）
 * @return {*}
 */
export const fetchCommonData: any = createAsyncThunk(
  'app/getCommonData',
  async (
    params: CommonParams = {
      terminal: 'pc'
    }
  ) => await request.get('/nav/getMainNav', params)
)

export default AppSlice.reducer
