import React, { useEffect, useRef } from 'react'

import { Processor, Type } from '@utils/tool'

type PromiseType<T> = (...args: any[]) => Promise<T>
//推导promise类型
type UnPromisify<T> = T extends PromiseType<infer U> ? U : never

type Options = {
  debounced?: number //请求防抖时间
  key?: string //用于debounced和cacheTimer
  cacheTimer?: number //请求结果缓存时间,第一次请求优先
  token?: string // token设置唯一的请求,会缓存请求
  initValue?
}

const topDeounced = () => {
  let queue: Function[] = []
  let errorQueue: Function[] = []

  const deboundFn = Processor.timeout()

  return (callback: Type.DefineFunc<Promise<any>>, d: number) => {
    deboundFn(callback, d)
    //执行时回调
    deboundFn.onRun(async (result: Promise<any>) => {
      const data = await result.catch((err) => {
        errorQueue.pop()

        errorQueue.forEach((remainingTask) => {
          remainingTask(data)
        })

        queue = []
        errorQueue = []
        throw new Error(err)
      })

      queue.pop()

      queue.forEach((remainingTask) => {
        remainingTask(data)
      })

      queue = []
      errorQueue = []
    })

    return {
      then(callback: (result: Promise<any>) => any) {
        queue.push(callback)
        return this
      },
      catch(callback: (error: any) => any) {
        errorQueue.push(callback)
        return this
      }
    }
  }
}

const cacheDeounced = () => {
  let data
  let isCache = false
  const deboundFn = Processor.throttle()

  return async (callback: Type.DefineFunc<Promise<any>>, d: number) => {
    deboundFn(() => (isCache = false), d)

    if (isCache) {
      return callback(data)
    } else {
      isCache = true
      data = await callback()
    }

    return data
  }
}

const topDeouncedGroup: Type.DefineObject<ReturnType<typeof topDeounced>> = {}
const cacheDebouncedGroup: Type.DefineObject<ReturnType<typeof cacheDeounced>> =
  {}

const uniqueCache = {}

/**
 * 请求处理
 * @param options 配置防抖等
 */
const useSearch = function <T extends (arg?: any) => any>(
  fn: T,
  dep: any[] | ['mutation'] = [],
  options: Options = {}
) {
  const { debounced, cacheTimer, key, token, initValue } = options
  const [state, useState] = React.useState<UnPromisify<T>>(initValue)
  const [isLoading, useLoading] = React.useState(false)
  const [isError, useError] = React.useState(false)
  const [err, useErrorState] = React.useState<string>()
  const isMutation = dep.includes('mutation')
  const debounSearch = React.useMemo(() => Processor.timeout(), [])
  topDeouncedGroup[key] || (topDeouncedGroup[key] = topDeounced())
  cacheDebouncedGroup[key] || (cacheDebouncedGroup[key] = cacheDeounced())

  const onSucess = (data) => {
    useState(data)
    useLoading(false)
    useError(false)
    useErrorState(undefined)
    return data
  }

  const onError = (err) => {
    useError(true)
    useLoading(false)
    useErrorState(err)
    //catchError?.(err)
    throw new Error(err)
  }

  const search = (...params): Promise<UnPromisify<T>> => {
    const result = fn(...params)

    if (Type.isPromise(result)) {
      useLoading(true)
    }

    return result?.then?.(onSucess, onError)
  }

  React.useEffect(() => {
    if (isMutation) {
      return
    }

    if (!Type.isNever(token)) {
      if (Type.isNever(uniqueCache[token])) {
        search().then((data) => {
          uniqueCache[token] = data
        })
      } else {
        onSucess(uniqueCache[token])
      }

      return
    }

    if (Type.isNumber(cacheTimer) && Type.isString(key)) {
      useLoading(true)
      const cacheDeounced = cacheDebouncedGroup[key]
      cacheDeounced(() => search(), debounced)
        .then(onSucess)
        .catch(onError)

      return
    }

    if (Type.isNumber(debounced) && Type.isString(key)) {
      useLoading(true)
      const topDeounced = topDeouncedGroup[key]
      topDeounced(() => search(), debounced)
        .then(onSucess)
        .catch(onError)

      return
    }

    if (Type.isNumber(debounced)) {
      debounSearch(() => search(), debounced)
      return
    }
    search()
  }, dep)

  const mutation = (...params: Parameters<T>) => {
    return search(...params)
  }

  const refetch = () => {
    return search()
  }

  return {
    data: state,
    useLoading,
    isLoading,
    err,
    isError,
    mutation,
    refetch
  }
}

/**
 * 观察子节点第一次发生变化并做对应操作
 * @returns ref 被观察的节点的父容器
 */
const useNodeMutation = (callback, dep = []) => {
  const ref = useRef<{ el: HTMLDivElement }>({ el: null })

  useEffect(() => {
    const targetNode = ref.current.el
    let isCallback = false

    const isHasMutation = MutationObserver

    const observer =
      isHasMutation &&
      new MutationObserver(function (mutations) {
        mutations.forEach(function () {
          if (isCallback) return
          callback()
          isCallback = true
        })
        observer.disconnect()
      })
    const config = { childList: true, subtree: true }
    observer?.observe(targetNode, config)

    //兼容未知情况
    setTimeout(
      () => {
        if (isCallback) return
        callback()
        isCallback = true
        observer?.disconnect()
      },
      !isHasMutation ? 0 : 3000
    )

    return () => {
      observer?.disconnect()
    }
  }, dep)

  return ref
}

export { useSearch, useNodeMutation }
