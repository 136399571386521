import React from 'react';

export const dispatchGlobalEvent = (event: string, data = {}) => {
  window.dispatchEvent(new CustomEvent(event, { detail: data }))
}
export default function useGlobalEvent(event: string, callback) {
  React.useEffect(() => {
    const action = (event) => {
      callback(event.detail)
    }

    window.addEventListener(event, action)
    return () => {
      window.removeEventListener(event, action)
    }
  })
}
