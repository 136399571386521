import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { getHome } from './action'
import { getHomeRoom } from '@api/hernest/roomIdea'

export const fetchHomeData: any = createAsyncThunk(
  'home/getHome',
  async () => await getHome({ terminal: 'pc', page: 'home' })
)

export const fetchHomeRoom: any = createAsyncThunk(
  'home/getHomeRoom',
  async () => await getHomeRoom()
)

export interface HomeState {
  banners: any
  newArrivals?: any[]
  bestSeller?: any[]
  flashSale?: any[]
  luckyWheelId?: string | number
  roomIdea?: {
    groupList?: any[]
    room?: any[]
  }
}

const initialState: HomeState = {
  banners: {},
  newArrivals: [],
  bestSeller: [],
  flashSale: [],
  luckyWheelId: 0,
  roomIdea: {}
}

export const HomeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    // setBanners: (state, action: PayloadAction<[]>) => {
    //   state.banners = action.payload
    // }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHomeData.fulfilled, (state, action) => {
        state.banners = action.payload.banners
        state.newArrivals = action.payload.productFlow?.new || []
        state.bestSeller = action.payload.productFlow?.hot || []
        state.flashSale = action.payload.productFlow?.flash || []
        state.luckyWheelId = action.payload?.turntable?.id || 0
      })
      .addCase(fetchHomeRoom.fulfilled, (state, action) => {
        state.roomIdea = action.payload
      })
  }
})

// export const { setBanners } = HomeSlice.actions

export default HomeSlice.reducer
