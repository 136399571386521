import { request } from '@utils/request'
import { HomeType } from './types'

export type getHomeParamsType = { terminal: string; page: string }

/**
 * 首页数据
 * @types-Home
 * getHome created by Home api
 */
export const getHome = async (params: getHomeParamsType) => {
  return request.get<HomeType>('/page/detail', params).then((res) => {
    return res.data
  })
}
/* @types-Home */
