import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = {} as const

export const pageSlice = createSlice({
  name: 'page',
  initialState,
  reducers: {
    update: (state, action: PayloadAction<any>) => {
      // state.data = action.payload
      Object.assign(state, action.payload)
    }
  }
})

export default pageSlice.reducer
