import { Knife as Dataman, Type } from '../../index'

/**
 *
 * @param observerType 观察者类型
 * @type P params on回调函数参数类型,emit参数发射类型
 * @type T target on回调函数观察者类型
 * @returns
 *
   const event = Event();

   event.emit(2);

   event.on((a) => {}, 'pig');
 */
const Event = function <
  P extends any[],
  T extends readonly (string | symbol | number)[] = []
>(observerType?: T) {
  observerType
  const ZERO = Symbol('zero')
  const ONLY = Symbol('only')
  type Subscriber = {
    actions: Type.DefineFunc[]
    observer: T[number] | typeof ZERO
  }[]
  let subscriber: Subscriber = []
  /**
   *
   * 获取指定观察者并发射事件
   * @example
   * takeObserver('pig');
   * takeObserver(['pig','cat']).emit()
   */
  const takeObserver = (_target: T[number] | T[number][]) => {
    const target = Type.array<any[]>(_target as []).map((t) => ({
      observer: t
    }))
    const source = Dataman.pick(subscriber, target)
    return {
      source,
      eject: subEject(source),
      emit: subEmit(source),
      subEmit: subEmit
    }
  }
  /**
   * 根据观察者抛出emit函数
   * @example
   *  emit(subscriber)(...params);
   */
  const subEmit = (subscriber: Subscriber) => {
    /**
     * 发送事件
     * @example
     * event.emit();
     */
    return function emit<R extends any[]>(...params: P) {
      const result = subscriber
        .map((i) => {
          return i.actions.map((cb) => cb(...params))
        })
        .flat(1) as R

      return {
        returns: result,
        takeObserver
      }
    }
  }
  /**
   * 根据观察者抛出任务
   */
  const subEject = (subscriber: Subscriber) => {
    /**
     * 发送任务
     * @example
     * event.eject();
     */
    return function eject<R extends any[]>() {
      const result = subscriber
        .map((i) => {
          return i.actions
        })
        .flat(1) as R

      return result
    }
  }
  /**
   * 取消观察
   * @param { string | symbol } observer 注明观察者类型
   * @param callback 回调函数
   * @example
   * event.off(); //取消所有
   * event.off('pig'); //取消名为pig的观察者
   * event.off('pig', () => {}); //取消名为pig的指定callback
   */
  const off = (observer?: T[number], callback?: Type.DefineFunc) => {
    //清空所有
    if (Type.isNever(observer) && Type.isNever(callback)) {
      subscriber.length = 0
      return
    }
    //清空指定观察者下所有
    if (!Type.isNever(observer)) {
      subscriber = Dataman.omit(subscriber, { observer })
    }
    //清空指定观察者下指定目标
    if (!Type.isNever(observer) && !Type.isNever(callback)) {
      const el = Dataman.find(subscriber, { observer }, { actions: [] })
      el.actions = Dataman.omit<any[]>(el.actions, (i) => i === callback)
    }
    // const el = Dataman.find(subscriber, { observer });
    // const target = Type.isNever(observer) ? zero : observer;
    // if (Type.isNever(el)) {
    //   subscriber.push({ observer: target, actions: [callback] });
    // } else {
    //   el.actions.push(callback);
    // }
  }
  /**
   * 观察emit响应
   * @param callback 回调函数
   * @param observer 注明观察者来源
   * @example
   *
   * event.on(() => {}, 'pig');
   * event.on(() => {});
   */
  const on = (callback: (...params: P) => any, observer?: T[number]) => {
    const el = Dataman.find(subscriber, { observer })

    const target = Type.isNever(observer) ? ZERO : observer

    if (Type.isNever(el)) {
      subscriber.push({ observer: target, actions: [callback] })
    } else {
      el.actions.push(callback)
    }
  }

  /**
   * 观察emit响应无队列,唯一观察者
   * @param callback 回调函数
   * @param observer 注明观察者来源
   * @example
   *
   * event.only(() => {}, 'pig');
   * event.only(() => {});
   */
  const only = (callback: Type.DefineFunc, observer?: T[number]) => {
    const el = Dataman.find(subscriber, { observer })

    const target = Type.isNever(observer) ? ONLY : observer

    if (Type.isNever(el)) {
      subscriber.push({ observer: target, actions: [callback] })
    } else {
      el.actions[0] = callback
    }
  }

  return {
    on,
    off,
    only,
    takeObserver,
    eject: subEject(subscriber),
    emit: subEmit(subscriber)
  }
}
/**
 * 创建event hook
 * 通过event进行单向数据流状态管理
 * @param event
 * @returns
 * @example
// 创建event hook
const { useEmit, useOn } = Event.createHook(Event<['富婆']>());
// useOn 使用
const BaoanSunzi = () => {
  //此时state为富婆
  const { state } = useOn((topRef) => topRef);
  return <div />;
};
const Baoan = () => {
  return <BaoanSunzi />;
};
// useEmit 使用
const Index = () => {
  //组件初次挂载发送参数
  const emit = useEmit().effect(() => ['富婆']);
  // emit(2); 直接发送参数2
  return <Baoan />;
};
 */
// Event.createHook = function <T extends ReturnType<typeof Event>>(event: T) {
//   let id = 0
//   /**
//    * 满足单向数据流通信,useEmit用于父组件,非全局数据管理,每份useEmit对应一个唯一的父组件
//    * //@param parentSymbol 父组件标识一旦第一次被引用 将被标识为唯一性
//    * @returns
//    */
//   const useEmit = () => {
//     const currentId = React.useMemo(() => {
//       return String(id++)
//     }, [])
//     /**
//      * 发送参数给useOn的回调函数
//      * @param params
//      */
//     function emit<R extends any[]>(...params: Parameters<T['emit']>) {
//       const currentOb = event.takeObserver([currentId])
//       const source = Dataman.pick(
//         currentOb.source,
//         (t) => t.observer.split(',')[0] === currentId
//       )
//       return currentOb.subEmit(source)<R>(...params)
//     }
//     /**
//      * as useEffect 默认第一次挂载调用
//      * @param depsVal
//      */
//     function effect(
//       callback: () => Parameters<T['emit']> | Promise<Parameters<T['emit']>>,
//       deps: React.DependencyList = []
//     ) {
//       useOnMount(async () => {
//         const params = await callback()
//         emit(...params)
//       }, deps)
//       return emit
//     }

//     return Object.assign(emit, { effect })
//   }
//   /**
//    * 用于子组件接受父组件emit过来的参数
//    * @returns state 回调函数的返回值作为state
//    * @example
//       const { state } = useOn((f) => 2, 'pig').deps([1]); // state为2
//    */
//   const useOn = function <R>(
//     callback: (...arg: Parameters<Parameters<T['on']>[0]>) => R,
//     symbol: string = ''
//   ) {
//     //
//     const observeId = React.useMemo(() => String(id), [event])

//     const [state, useState] = React.useState<R>()

//     const ref = React.useRef<React.DependencyList>([])

//     React.useEffect(() => {
//       const stateUse = (...params: Parameters<Parameters<T['on']>[0]>) => {
//         const result = callback(...params)
//         useState(result)
//       }

//       event.on(stateUse, [observeId, symbol].join(','))

//       return () => {
//         event.off([observeId, symbol].join(','), stateUse)
//       }
//     }, ref.current)
//     /**
//      * as useEffect deps param
//      * @param depsVal
//      * @returns
//      */
//     const deps = function (depsVal: React.DependencyList) {
//       ref.current = depsVal
//       return handler
//     }

//     const handler = { state, deps }

//     return handler
//   }

//   return { useEmit, useOn, current: { id } }
// }

export default Event
