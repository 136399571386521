import { request } from '@utils/request'

/**
 * @description: 获取首页 room idea 数据
 * @param {object} params
 * @return {*}
 */
export const getHomeRoom = async (params?: { [propName: string]: any }) => {
  return request.get('/room/getHomeRoom', params).then((res) => {
    return res.data
  })
}

/**
 * @description: 获取详情页 room idea 数据
 * @param {string} id
 * @return {*}
 */
export const getProductRoom = async (id: string) => {
  return request
    .post('/room/getProductRoom', {
      products_sku_id: id
    })
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      throw new Error(err)
    })
}

export const getRoomList = async (params?: { [propName: string]: any }) => {
  return request
    .post('/room/list', {
      ...params
    })
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      throw new Error(err)
    })
}
/****
 * @description: 获取系列页 room idea 数据
 * @param {string} catId
 * @return {*}
*/
export const getCategoryRoom = async (catId:string) => {
  return request
    .post('/room/getCategoryRoom', {
      catId
    })
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      throw new Error(err)
    })
}
