import { wrapperObject } from '../interface'
import Dataman, { Knife } from '../knife'
import { Processor } from '../processor'

/**
 * 类型检测包括类型转换
 *
 * */

const TYPE_TUPLE = [
  '[object Number]',
  '[object String]',
  '[object Function]',
  '[object Array]',
  '[object Object]',
  '[object Null]',
  '[object Undefined]',
  '[object Boolean]'
] as const

const WRAP_TYPE_TO_TYPE_TUPLE = {
  '[object Number]': 'number',
  '[object String]': 'string',
  '[object Function]': 'function',
  '[object Array]': 'array',
  '[object Object]': 'object',
  '[object Null]': 'null',
  '[object Undefined]': 'undefined',
  '[object Boolean]': 'boolean',
  '[object Symbol]': 'symbol'
} as const

const WRAP_TYPE_TO_TYPE_TUPLE_PRO = {
  '[object Number]': Number,
  '[object String]': String,
  '[object Function]': Function,
  '[object Array]': Array,
  '[object Object]': Object,
  '[object Null]': null,
  '[object Undefined]': undefined,
  '[object Boolean]': Boolean,
  '[object Symbol]': Symbol
} as const

type Wap<P> = P extends keyof typeof WRAP_TYPE_TO_TYPE_TUPLE
  ? [(typeof WRAP_TYPE_TO_TYPE_TUPLE)[P]]
  : null

type Lower = Wap<keyof typeof WRAP_TYPE_TO_TYPE_TUPLE>[0]

/**
 * 检查数组里的值是否唯一
 */
function isUnique<T>(data: T[], callback = (i: T) => i as any): boolean {
  const hash = new Map()
  return !Knife.each(Knife.protectNever(data), (i) => {
    const result = callback(i)
    const include = hash.get(result)
    if (include) {
      return Knife.stop()
    }
    hash.set(result, result)

    return
  })
}
/**
 * 获取数据对应的 toString 类型
 * @param { any } type
 * @param {boolean} lower  返回小写格式的字符串
 * @returns { string }
 * @example
 *
 * getType(1) // '[object Number]'
 * getType(()=>{}) // '[object Function]'
 *
 * */
function getType(type, lower?: boolean): (typeof TYPE_TUPLE)[number] | Lower {
  if (lower) {
    return WRAP_TYPE_TO_TYPE_TUPLE[Object.prototype.toString.call(type)]
  }
  return Object.prototype.toString.call(type)
}

function getWrapperTypes(
  type
): (typeof WRAP_TYPE_TO_TYPE_TUPLE_PRO)[keyof typeof WRAP_TYPE_TO_TYPE_TUPLE_PRO] {
  return WRAP_TYPE_TO_TYPE_TUPLE_PRO[Object.prototype.toString.call(type)]
}

/**
 * toString类型检查
 * @param {data} any
 * @param {type} wrapper Number | Function | Object | String | Boolean | Symbol | Date | RegExp | Promise<any>...
 * @returns { boolean }
 * @example
 *
 * isType([],Array) // true
 * isType(()=>{},Function) // true
 * isType({},Object) // true
 * isType(1,Number) // true
 * isType(1,Boolean) // false
 *
 * */
function isType(
  data,
  type:
    | 'number'
    | 'object'
    | 'function'
    | 'string'
    | 'boolean'
    | 'symbol'
    | 'regExp'
): boolean
function isType<T = boolean>(
  data,
  type: wrapperObject | 'Null' | 'Undefined'
): T
function isType(data, type: wrapperObject | string) {
  if (isString(type)) {
    return getType(data).toLowerCase() === `[object ${type}]`
  }

  if (!isFunc(type)) {
    throw new Error(
      'type is not javascript wrapper (Number,Array,Boolean....) '
    )
  }

  return getType(data) === `[object ${type?.name}]`
}
/**
 * 创建一个特别的数组类型,它是枚举类型和数组类型的融合,数组中的元素默认作为他的key
 * @param { stirng[] } data
 * @returns
 */
function enumArray<T extends readonly string[]>(
  data: T
): T & { [P in T[number]]: P } {
  const newData = Dataman.map(data)
  newData.forEach((key) => {
    newData[key] = key
    Processor.setPropOutLoop(newData, key)
  })
  return newData as any
}

/**
 * 判断对象是否具有某种属性
 * @param obj
 * @param key
 * @returns
 */
function hasKey(obj = {}, key: string): boolean {
  return Object.prototype.hasOwnProperty.call(obj, key)
}

/**
 * 强制数组类型并且排空
 * */
function array<T extends any[]>(arr, defualtVal?: any[]): T
function array<T>(arr: T, defualtVal?: any[]): T
function array(arr, defualtVal?: any[]): any[] {
  if (!Type.isNever(defualtVal)) {
    return Array.isArray(arr) ? arr : defualtVal
  }
  return Array.isArray(arr) ? arr : [arr].filter((i) => !Type.isNever(i))
}

/**
 * 强制转换m目标类型为键值对类型
 * */
function object<T extends Type.DefineObject>(obj?: T): T
function object<T = {}>(obj, returnValue: T): Type.DefineObject<T>
function object(obj, returnValue = {}) {
  return isType(obj, Object) ? obj : returnValue
}
/**
 * 断言函数类型
 * */
function assertFunction<T, R>(
  val?: T,
  defaultVal?: (...params) => R
): (...params) => R
function assertFunction(val?, defaultVal = () => val) {
  return Type.isFunc(val) ? val : defaultVal
}
/**
 * 强制转换成number类型, 参数2字符串转换规则
 * @param num
 * @param {string} rule 字符转换规则 比如2013-2013-2013
 * @param {number} rule 如果num不是number的返回默认值
 * @example
 * number('100,100',',') // 100100
 * number('z',5) //  5
 * number('9',5) //  9
 * */
function number<D extends string>(num, rule: D): number
function number<D extends number = 0>(num, defaultVal?: D): number | D
function number(num, rule = 0) {
  /** 特殊字符串转数字比如日期: 2013-2013-2013 */
  if (Type.isString(rule)) {
    return Type.number(Type.String(num).split(rule).join(''))
  }

  const n = Number(num)
  if (n === Infinity) return rule
  return n || rule
}
/**
 *
 *检查当前值是否是JSON
 */
function isJSON(value: string) {
  try {
    const str = value.replace(/\s*/g, '')
    const m = JSON.parse(str)
    if (Type.isArray(m) || Type.isObject(m)) {
      return true
    }
    return false
  } catch (error) {
    return false
  }
}
/**
 *
 * 检查值是否是undefiend或者null
 */
function isNever(val): val is void {
  return val === undefined || val === null
}
/**
 * 空值合并
 * @example
 * nullCoalesce(null,1) // 1
 * nullCoalesce(undefiend,2) // 2
 * nullCoalesce(0,'zero') // 0
 * nullCoalesce([],'zero') // []
 */
// function nullCoalesce<V extends void | never, D = V>(val, defualtVal: D): D;
function nullCoalesce<V, D>(
  val: V,
  defualtVal: D
): V extends void | never ? D : V {
  if (Type.isNever(val)) {
    return defualtVal as any
  }
  return val as any
}
/**
 *
 * 检查值是否是undefiend或者null或者NaN
 */
function isNothing(val): val is void {
  return val === undefined || val === null || Type.isNaN(val)
}
/**
 *
 * 检查值是否是NaN
 */
function isNaN(val) {
  return typeof val === 'number' && Number.isNaN(val)
}
/**
 * 判断值是否是整数 ( 包括'11'形式 )
 * */
function isInteger(n): n is number {
  if (!Type.isNumber(n)) return false
  return Number.isInteger(n)
}
/**
 *
 * 判断是否为数字(包括字符串'111'的形式)
 * */
function isNumber(num): num is number {
  if (Type.isEmpty(num)) return false
  return (
    !isNaN(Number(num)) && (typeof num === 'string' || typeof num === 'number')
  )
}
/**
 *
 * 判断值是否为undefiend
 */
function isUndefined(val): val is undefined {
  return val === undefined
}
/**
 * 判断值是否为函数
 * */
function isFunc<T extends Function = Function>(fn): fn is T {
  return fn instanceof Function
}
/**
 *
 * 检查对象是否为空,
 * @param strict 启用严格模式, 默认false, 如果启用检查对象上的每一个键值是否为空
 * */
function isEmpty(val, strict = false): boolean {
  //严格模式
  if (strict) {
    if (Type.isArray(val) || Type.isObject(val)) {
      const isEmpty = Dataman.each(val, (value) => {
        if (!Type.isEmpty(value)) {
          return Dataman.stop()
        }
        return
      })
      return isEmpty
    }
    return Type.isEmpty(val, false)
  }
  return (
    val === undefined ||
    val === null ||
    val === '' ||
    ((Type.isArray(val) || Type.isObject(val)) &&
      Object.keys(val)?.length === 0)
  )
}
/**
 *
 * 检测值是否为空 : undefined,null,''
 * */
function isVoid(val): val is void & '' {
  return val === undefined || val === null || val === ''
}
/**
 *
 * 检查键值对类型
 * */
function isObject<T extends object = object>(obj): obj is T {
  return isType(obj, Object)
}
/**
 *
 * 检查数组类型
 */
function isArray<T = any>(arr): arr is T[]
function isArray(arr): arr is any[] {
  return Array.isArray(arr)
}
/**
 *
 * 判断值是否可迭代: object | array
 * */
function isIter(val): val is Type.Iteration {
  return Type.isObject(val) || Type.isArray(val)
}
/**
 *
 * 判断值是否是Promise
 */
function isPromise(p): p is Promise<any> {
  let isPromise
  try {
    isPromise = p instanceof Promise
  } catch (error) {
    isPromise = false
  }
  return isPromise
}
/**
 *
 * 检查值是否为字符串
 * @param strict 严格默认 如果严格默认为true 诸如 '11' 这种形式的字符串不会被认为字符串
 * */
function isString(str, strict = false): str is string {
  if (strict) return !Type.isNumber(str) && typeof str === 'string'
  return typeof str === 'string'
}
/**
 * 检查可迭代类型是否为字符串,在可迭代类型中只要有一个元素不满足string就返回false
 * @param strict 严格默认 如果严格默认为true 诸如 '11' 这种形式的字符串不会被认为字符串
 */
function isStringIter<T extends Type.Iteration>(data: T, strict = false) {
  const isString = Dataman.each(data, (v) => {
    if (!Type.isString(v, strict)) {
      return Dataman.stop()
    }
    return
  })
  return isString
}
/**
 *
 * 如果非num默认0
 */
function toFixed(num, fix = 2) {
  return Type.number(Type.number(num).toFixed(fix))
}
/**
 *
 * 强制转换string类型
 * @param defaultVal 默认返回值 默认''
 * */
function String(s, defaultVal = ''): string {
  if (Type.isNumber(s) || Type.isString(s)) {
    return `${s}`
  }
  return defaultVal
}
/**
 *
 * 检查值是否满足某种数据结构
 * @param { string | object } typeDoclar 结构声明,
 * @param include  是否允许存在包含关系 默认不允许
 * @example
 * isConstruction({name:'zs',age:18},['name']) //false
 * isConstruction({name:'zs',age:18},['name','age']) //true
 * isConstruction({name:'zs',age:18},['name'],true) //true
 * //对键值进行类型检查
 * isConstruction({name:'zs',age:18},{name:'zs'},true) // true
 * isConstruction({name:'zs',age:18},{name:String},true) // true
 * isConstruction({name:'zs',age:18},{name:'zs1'},true) // false
 * isConstruction({name: 1 ,age:18},{name:String},true) // false
 *
 * @todo 加入数组数据结构支持
 * */
function isConstruction(
  val: Type.DefineObject,
  typeDoclar: Type.DefineObject<Type.wrapperObject>,
  include?: boolean
): boolean
function isConstruction(
  val: Type.DefineObject,
  typeDoclar: string[] | Type.DefineObject,
  include: boolean = false
) {
  if (Type.isIter(val)) {
    const targetKeys = Object.keys(val)
    const typeDoclarKeys = Type.array(typeDoclar)
    //对对象的数据结构检查
    if (Type.isObject(val)) {
      //如果typeDoclar为字符串那么检查对象是否有对应属性
      if (Type.isArray(typeDoclar) && Type.isStringIter(typeDoclar, false)) {
        const notInclude = !include
        if (targetKeys.length !== typeDoclarKeys.length && notInclude)
          return false
        //检查对象属性名是否与typeDoclar一一对应
        const ownKey = !Dataman.map(targetKeys, (k) =>
          typeDoclarKeys.some((tk) => tk === k)
        ).some((b) => b === false)

        //typeDoclar可以被对象属性包含
        if (targetKeys.length > typeDoclarKeys.length && include && ownKey) {
          return true
        }
        //对象属性与typeDoclar严格匹配
        if (targetKeys.length === typeDoclarKeys.length && ownKey) {
          return true
        }
        return false
      }

      //检查对象是否拥有指定属性类型声明
      if (Type.isObject(typeDoclar)) {
        const ownType = !Dataman.map(
          //@ts-expect-error
          typeDoclar,
          (typeVal: Type.wrapperObject | any, key) => {
            const targetVal: any = val[key]

            if (Type.isFunc(typeVal)) {
              return targetVal instanceof typeVal
            }

            return targetVal === typeVal
          }
        ).some((b) => b === false)

        if (!ownType) return false
        /* 检查存在包含关系 include*/
        if (include && ownType && targetKeys.length >= typeDoclarKeys.length)
          return true

        if (ownType && targetKeys.length === typeDoclarKeys.length) return true

        return false
      }
      throw new Error('type docalr is error: not string | string[] | Object')
    }
  }
  return false
}

/**
 *
 * 小写转换
 * */
function lower(str: string) {
  return str?.toLowerCase()
}
/**
 *
 * 大写转换
 * */
function upper(str: string) {
  return str?.toUpperCase()
}
/**
 * 检查值是否被某个值所包含
 * 或者检查某组值是被另一组值包含
 */
function include<
  V,
  A extends readonly any[],
  C extends (target: A[number]) => boolean
>(val: V | C, arr: A) {
  //值比较函数
  const eqFunc = Type.function(val, (target) => target === val)

  Type.function(val)
  return arr.some((cell) => {
    return eqFunc(cell)
  })
}
/**
 * 集合比较集合
 * 或者检查某组值是被另一组值包含
 */
function includes<
  V extends readonly any[],
  A extends readonly any[],
  C extends (target: A[number], source: V) => boolean
>(val: V, arr: A, callback?: C) {
  if (!Type.isArray(val)) {
    return false
  }
  //值比较函数
  const eqFunc = Type.nullCoalesce(
    callback as C,
    (target, source) => target === source
  )

  return arr.some((cell) => {
    return val.find((i) => eqFunc(cell, i))
  })
}
/**
 * 检查集合A中的每个元素是否全等于B
 */
function onlyOne(val, arr: any[]): boolean {
  return arr.every((cell) => {
    return cell === val
  })
}
/**
 * 检查两值是否相等
 * */
function equal<S extends readonly any[], T extends S>(
  source: S,
  target: T,
  callback?: (
    sourceData: S[number],
    targetData: T[number],
    key: string,
    index: number
  ) => boolean
): boolean
function equal<S extends Type.DefineObject, T extends S>(
  source: S,
  target: T,
  callback?: (
    sourceData: S[keyof S],
    targetData: T[keyof T],
    key: keyof S,
    index: number
  ) => boolean
): boolean
function equal<S, T extends S>(source: S, target: T, callback?) {
  if (Type.isFunc(callback)) {
    //首先检查数据结构是否一致
    const cst = Type.isConstruction(
      source as Type.DefineObject,
      target as Type.DefineObject
    )
    if (cst === false) return false
    return Dataman.map(source as Type.DefineObject, (data, key, index) =>
      callback(data, target[key], key, index)
    ).every((b) => b === true)
  }
  if (Type.isNaN(source) && Type.isNaN(target)) {
    return true
  }
  return source === target
}

function isBaseType(data) {
  const baseType = [
    Number,
    String,
    Boolean,
    Function,
    'Null',
    'Undefined'
  ] as const
  const isBaseType = (target: (typeof baseType)[number], source: typeof data) =>
    isType(source, target)
  return include((target) => isBaseType(target, data), baseType)
}

/**
 * 数据类型断言,将一个数据断言成你所希望的数据类型
 * @example
  assertType(1, Object) // {}
  assertType(1, {}) //{}
  assertType('1', 'ssss') //'ssss'
  @example 按字面量类型断言
  assertType([0,0], [{}, 0]) // [{}, 0]
  @todo 按类型声明断言
  assertType({children:[1]}, { children: Array }) // {children:[1]}
  assertType({children:{}}, { children: Array }) // {children:[]}
  @todo 复杂类型断言
  // [{age:18}, 0, 2,{ sex:0, height:180 } ]
  assertType([{age:18},0,2,{ height:120 } ], [Object, 0, Number, { sex:Number,height:180 } ])
  // [{age:18}, 0, 3,{ sex:1 , height:180 } ]
  assertType([{age:18},1,3,{ sex:1 ,name:'zs' }], [Object, 0, Number,{ sex:Number,height:180 }])
 */
function assertType(
  data,
  expectType: Type.BaseType | wrapperObject | object | any[]
) {
  //如果是包装类型的参数
  if (isFunc(expectType)) {
    return isType(data, expectType) ? data : expectType()
  }
  //字面量参数基于prototype做类型比较
  return getType(data) === getType(expectType)
    ? data
    : expectType.constructor?.()
}

/**
 * 获取所期望的类型参数，用于接口数据兜底处理。
 * @param fn 取值函数
 * @param type 默认类型参数或者包装类型
 * @example 当该字段的上级为null或者返回值类型不为Object时返回字面量
   // const data=[] or data[{ shopList:null }]
 * get(()=>data[0].shopList,Object) // {}
   @example 通过字面量获取默认值
   get(()=>data[0].shopList,[1,2,3]) // [1,2,3]
   get(()=>data.length,[1,2,3]) // 值为数字返回数组 [1,2,3]
   get(()=>[data.length],[1,2,3]) //[0]
 */
function get<T>(fn: () => T, type: wrapperObject): T {
  try {
    return assertType(fn(), type)
  } catch (error) {
    //打印调试信息用于定位问题

    console.warn(error)
    return isFunc(type) ? type() : type
  }
}

const Type = {
  get,
  assertType,
  isBaseType,
  isUnique,
  getType,
  isType,
  enumArray,
  hasKey,
  array,
  object,
  equal,
  onlyOne,
  isNaN,
  isConstruction,
  isFunc,
  includes,
  isArray,
  nullCoalesce,
  function: assertFunction,
  assertFunction,
  include,
  upper,
  lower,
  isObject,
  isStringIter,
  isIter,
  isNumber,
  isString,
  number,
  toFixed,
  isPromise,
  isVoid,
  isEmpty,
  isUndefined,
  isInteger,
  isNothing,
  isNever,
  isJSON,
  String,
  getWrapperTypes
} as const

export default Type
