import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Knife } from '@utils/tool'

import { getHome, getHomeParamsType } from './action'

export const fetchHomeData: any = createAsyncThunk(
  'home/getHome',
  async () => await getHome({ terminal: 'pc', page: 'home' })
)

export const fetchHomeDataV2: any = createAsyncThunk(
  'home/getHome',
  async (params: getHomeParamsType) => await getHome(params)
)

export interface HomeState {
  banners: any
  newArrivals?: any[]
  bestSeller?: any[]
  flashSale?: any[]
  // fixed: useHome.ts文件中类型报错
  productFlow?: {
    new?: any[]
    hot?: any[]
    flash?: any[]
  }
  turntable?: {
    id?: string | number
    autoOpen?: boolean
  }
}

export const initialState: HomeState = {
  banners: {},
  newArrivals: [],
  bestSeller: [],
  flashSale: []
}

export const HomeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    // setBanners: (state, action: PayloadAction<[]>) => {
    //   state.banners = action.payload
    // }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchHomeData.fulfilled, (state, action) => {
      state.banners = action.payload.banners
      state.newArrivals = action.payload.productFlow?.new || []
      state.bestSeller = action.payload.productFlow?.hot || []
      state.flashSale = action.payload.productFlow?.flash || []
      state.turntable = action.payload.turntable
    })
  }
})

// export const { setBanners } = HomeSlice.actions

export default HomeSlice.reducer
