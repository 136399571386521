import { createAsyncThunk } from '@reduxjs/toolkit'
import { request } from '@utils/request'

const API_NAME = 'category'
const action = {
  fetchList: `${API_NAME}/getCategoryProduct`,
  fetchFilter: `${API_NAME}/getCategoryProductFilter`,
  fetchFlowProducts: `product_flow/getProductflow` // 闪购商品页，热销商品页，新品商品页
}

export const fetchProducts: any = createAsyncThunk(
  action.fetchList,
  async (params) => await request.post(`/${action.fetchList}`, params)
)

export const fetchFilter: any = createAsyncThunk(
  action.fetchFilter,
  async (params) => await request.post(`/${action.fetchFilter}`, params)
)

export const fetchFlowProducts: any = createAsyncThunk(
  action.fetchFlowProducts,
  async (params) => await request.post(`/${action.fetchFlowProducts}`, params)
)
