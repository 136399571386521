import { SyntheticEvent, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { useLocation } from 'react-router'
import URLParse from 'url-parse'

import { Knife, Type } from '@utils/tool'

import { request } from './request'
import { getItem, setItem } from './storage'

export const inBrowser: boolean = typeof window !== 'undefined'

/**
 * 阻止冒泡
 */
export const cancelBubble = (e: SyntheticEvent) => {
  e.stopPropagation()
  e.nativeEvent.stopImmediatePropagation()
}

/**
 * @description 获取当前url query
 * @returns array
 */
export const getUrlQuery = () => {
  const defaultQuery: any = location.search ? location.search.substring(1) : ''
  return defaultQuery ? defaultQuery.split('&') : []
}
/**
 * @description 根据参数name名字返回获取当前url中query值
 * @returns 返回字符串
 */
export const getUrlParam = (name: string): string => {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
  const regex = new RegExp('[\\?&]' + name + '=([^&#]*)')
  const results = regex.exec(location.search)
  return results === null
    ? ''
    : decodeURIComponent(results[1].replace(/\+/g, ' '))
}

function randomNum(max: number, min: number) {
  const num = Math.floor(Math.random() * (max - min + 1)) + min
  return num
}

/**
 * 模拟异步请求数据
 */
const mockRequest = function <T>(data: T, timestamp = randomNum(300, 2000)) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(data)
    }, timestamp)
  }) as Promise<T>
}

function isBrowser() {
  return typeof window !== 'undefined' && typeof document !== 'undefined'
}

function isLocalDev() {
  return window.isLocalDev
}

function isMobile() {
  const width =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth
  const height =
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight
  return width < 768 || height < 576 // 这里将屏幕宽度小于 768px 或高度小于 576px 的设备判断为移动设备
}

export const isDev = process.env.NODE_ENV === 'development'

const setSearchParams = (obj: Type.DefineObject) => {
  return '?' + new URLSearchParams(obj).toString()
}

type Data<T> = T extends { type: Type.wrapperObject; default: any; mock: any }
  ? { type: Type.wrapperObject; default: any; mock: any }
  : T extends object
    ? {
        [P in keyof T]: T[P] extends Type.BaseType
          ? { type: Type.wrapperObject; default: any; mock: any }
          : Data<T[P]>
      }
    : T extends Array<infer F>
      ? Data<F>
      : { type: Type.wrapperObject; default: any; mock: any }

/**
 * @param data 源头数据
 * @param modelData 模板数据/模型数据
 * @example modelData 数据结构
 * const Types: Data<{ example }> = {
    example: {
    type: String,
    default: '',
    mock: 'hernest'
  }
}
 */
const model = <T>(data, modelData: Data<T>) => {
  const parsedModel = Knife.model({}, modelData, ({ atomModel }) => {
    return isDev ? atomModel.mock : atomModel.default
  })

  return Knife.model(data, parsedModel)
}

/**
 *validatePwd 检验密码
 * @param password The password to validate
 * @param minLen Minimum length. Default 9
 * @param maxLen Maximum length. Default 20
 * @returns Whether the password is valid
 */
export const validatePwd = (
  password: string,
  minLen: number = 6,
  maxLen: number = 20
): boolean => {
  if (password.length < minLen || password.length > maxLen) {
    return false
  }
  const passReg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$/
  // 查找数字、小写字母、大写字母、不包含空格
  return passReg.test(password)
}

/**
 * 获取search url参数
 */
const getSearchParams = function <T extends readonly string[]>(
  searchUrl: string,
  string: T
): { [P in T[number]]?: any } {
  const params = new URLSearchParams(searchUrl)
  return string.reduce((newObj, key) => {
    const searchParam = params.get(key)
    newObj[key] = searchParam
    return newObj
  }, {}) as any
}

/**
 * 返回相对路径的url,截取域名
 */
const getRelativeUrl = (string) => {
  const path = URLParse(string)
  return path.pathname + path.query
}

/**
 * @param str location pathname
 * @param reg 详情页 /-p-\d+\.html/, 类目页 /-c-\d+\.html/
 */
const getMetaTitle = (str: string, reg?: RegExp) => {
  // error: products/%C3%89milie-60%22-Modern-products/Ceiling-Fans-with-Lights-and-Remote,-Quiet-Reversible-6-Speeds-Motor,-Dimmable-LED-3-Color-(Black/Chrome)-p-289.html
  const regExp =
    reg ||
    /(?:.+)?(?:products|category|item|quickView)\/(.+?)-[pc]-\d+\.html(.+)?/

  return (
    Type.String(str)
      //获取末尾路径
      .replace(regExp, (_, _1) => _1)
      //转义a-a
      .replaceAll('-', ' ')
      //转义31_5 =31.5
      .replace(/(\d+)_(\d+)/g, (match, p1, p2) => `${p1}.${p2}`)
      //首字母字母转义大写
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  )
}

const getMetaTitleNormal = (str: string) => {
  return (
    Type.String(str)
      //首字母字母转义大写
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  )
}

/**
 * @description: 向下取整，保留小数
 * @param {number} num
 * @return {*} number
 */
const numberFloor = (num: number) => {
  return Number((Math.floor(num * 10000) / 10000).toFixed(4))
}

/**
 * @description: 像素转vw
 * @param {string | number} d
 * @return {*} string
 */
const px2vw = (d: number | string) => {
  if (Type.isNumber(d) && Type.number(d) === 0) return '0vw'
  if (!d) return ''
  if (typeof d === 'string' && d.indexOf('px') > -1) {
    return numberFloor(Number(d.split('px')[0]) / 750) * 100 + 'vw'
  }
  if (typeof d === 'number') {
    const m = numberFloor(d / 750)
    return m * 100 + 'vw'
  }
}
const px2rem = px2vw

/**
 * @description: 截流
 * @param {function} action
 * @param {number} delay
 * @return {*}
 */
const throttle = (func: () => void, delay: number) => {
  let timeout = null
  let lastRun = 0
  return function (...args) {
    if (timeout) {
      return
    }
    const elapsed = Date.now() - lastRun
    const runCallback = () => {
      lastRun = Date.now()
      timeout = false
      func.apply(this, args)
    }
    if (elapsed >= delay) {
      runCallback()
    } else {
      timeout = setTimeout(runCallback, delay)
    }
  }
}

/**
 * @description: 防抖
 * @param {function} func
 * @param {number} delay
 * @param {boolean} immediate
 * @return {*}
 */
const debounce = (func: () => void, delay: number, immediate: boolean) => {
  let timeout = null
  return function () {
    const context = this
    const args = arguments
    if (timeout) clearTimeout(timeout)
    if (immediate) {
      const callNow = !timeout
      timeout = setTimeout(function () {
        timeout = null
      }, delay)
      if (callNow) func.apply(context, args)
    } else {
      timeout = setTimeout(function () {
        func.apply(context, args)
      }, delay)
    }
  }
}
const reportStr = {
  ['two']: 'HERNEST_PC_',
  ['hernest-m']: 'HERNEST_M_',
  ['home']: 'HOME_PC_',
  ['home-m']: 'HOME_M_'
}
const prefix = reportStr[process.env.template]
/**
 * @description: LuckyOrange 埋点方法
 * @param {string} str 上报字符串
 * @return {*}
 */
const loTrack = (str: string) => {
  if (!inBrowser) {
    return
  }
  str = prefix + str
  isDev && console.log(str)
  try {
    window.LO && window.LO.events.track(str)
  } catch (error) {}
}
const injectScript = (
  src: string,
  callback: Function = () => {},
  async: boolean = false,
  id: string = '',
  onerror: any = () => {},
  ele: string = 'body'
) => {
  let injectEle =
      document.getElementsByTagName(ele)[0] ||
      document.getElementsByTagName('head')[0],
    script = document.createElement('script')
  script.type = 'text/javascript'
  script.src = src
  script.onload = function () {
    callback && callback(script)
  }
  script.async = async || false
  id ? script.setAttribute('id', id) : ''
  script.onerror = onerror || function () {}
  injectEle.appendChild(script)
}

const convertNodeToObj = (node) => {
  const obj = {}
  if (node.nodeType === Node.TEXT_NODE) {
    obj['value'] = node.textContent.trim()
  } else if (node.nodeType === Node.ELEMENT_NODE) {
    obj[node.nodeName] = {}
    if (node.hasChildNodes()) {
      node.childNodes.forEach((child) => {
        const childObj = convertNodeToObj(child)
        if (child.nodeName in obj[node.nodeName]) {
          if (!Array.isArray(obj[node.nodeName][child.nodeName])) {
            obj[node.nodeName][child.nodeName] = [
              obj[node.nodeName][child.nodeName]
            ]
          }
          obj[node.nodeName][child.nodeName].push(childObj)
        } else {
          obj[node.nodeName] = { ...obj[node.nodeName], ...childObj }
        }
      })
    }
  }
  return obj
}

//解析返回xml, 为Json对象
const parseXMLDataToJson = (data) => {
  const parser = new DOMParser()
  const xmlDoc = parser.parseFromString(data, 'text/xml')
  const xmlObj = convertNodeToObj(xmlDoc.documentElement)
  const jsonString = JSON.parse(JSON.stringify(xmlObj))
  return jsonString
}

const createdPortal = (children: React.ReactNode, id: string) => {
  if (!inBrowser) return
  const container = document?.getElementById(id)
  if (!container) return
  return createPortal(children, container || document?.body)
}

const isMobileProject = process.env.mobile

const getHVideoForm = (url: string) => {
  let videoSrc = url
  const isM = false
  const parse = URLParse(url)
  let id
  // const isVimod = URLParse(
  //   'https://player.vimeo.com/video/873295206?h=2b6cc83969'
  // )

  if (parse.host.indexOf('youtu.be') > -1) {
    if (isM) {
      // videoSrc = '/youtubeVideo/embed' + parse.pathname
    } else {
      id = parse.pathname.substring(1)
      videoSrc = 'https://www.youtube.com/embed' + parse.pathname
      videoSrc =
        videoSrc +
        `?autoplay=1&controls=1
        &loop=1&iv_load_policy=3&cc_load_policy=0&fs=0&modestbranding=1`
    }
  }

  //截取query参数
  if (parse.host.indexOf('youtube.com') > -1) {
    if (isM) {
      //videoSrc = '/youtubeVideo/embed' + id
    } else {
      id = getSearchParams(parse.query, ['v']).v
      videoSrc = 'https://www.youtube.com/embed/' + id
      videoSrc =
        videoSrc +
        `?autoplay=1&controls=1
        &loop=1&iv_load_policy=3&cc_load_policy=0&fs=0&modestbranding=1`
    }
  }

  let params = {} as { id: string } & Type.DefineObject
  if (parse.host.indexOf('vimeo.com') > -1) {
    console.log('viemo parse', parse)
    id = parse.pathname.substring(1).replace('video/', () => '')
    params = Object.fromEntries(
      new URLSearchParams(parse.query).entries()
    ) as any
    params.id = id
  }

  const platform =
    parse.host.indexOf('vimeo.com') > -1
      ? 'vimeo'
      : parse.host.indexOf('youtu') > -1
        ? 'youtobe'
        : 'local'

  //console.log('ssss', videoSrc, platform, id)

  return { videoSrc, platform, id, params } as const
}

const getAllSearchParams = (query: string) => {
  try {
    return Object.fromEntries(new URLSearchParams(query).entries())
  } catch (error) {
    return {}
  }
}

// 将 ‘2023-11-17 00:00:00’ 转换为日期对象
const transTimeToDate = (time: string) => {
  if (!time) return
  const parts = time.split(' ')
  const dateParts = parts[0].split('-')
  const timeParts = parts[1].split(':')

  // 创建日期对象
  const date = new Date(
    parseInt(dateParts[0]), // 年
    parseInt(dateParts[1]) - 1, // 月 (注意月份是从 0 开始)
    parseInt(dateParts[2]), // 日
    parseInt(timeParts[0]), // 小时
    parseInt(timeParts[1]), // 分钟
    parseInt(timeParts[2]) // 秒
  )
  return date
}

/**
 * @description: 返回当日剩余的小时数
 * @param {*} number
 * @return {*}
 */
export const getTimeDiff = (): number => {
  const now = new Date()
  const year = now.getFullYear()
  const month = now.getMonth()
  const day = now.getDate()
  const tomorrow = new Date(year, month, day + 1)
  tomorrow.setHours(0, 0, 0, 0)
  const timeDifference = tomorrow.getTime() - now.getTime()

  return timeDifference / (1000 * 60 * 60)
}

export const omitUrlSearch = (key, url = location.search) => {
  const allQuery = getAllSearchParams(url)
  //const { orderId, ...rest } = allQuery
  console.log('allQuery', allQuery)
  delete allQuery[key]
  history.replaceState(
    {},
    'checkout',
    location.pathname + setSearchParams(allQuery)
  )
}

export const checkTopBannerSameImgUrl = (urls: any[]) => {
  if (urls.length === 0) {
    return true
  }
  const newArr = urls.map((item) => item.navImg && item.navImg[0])
  const firstUrl = newArr[0].navImg

  for (let i = 1; i < newArr.length; i++) {
    if (newArr[i].navImg !== firstUrl) {
      return null
    }
  }
  return firstUrl
}

let maxLength = 100

const pushDetailHistory = (data: object) => {
  const arr = Type.array(getItem('localDetailHistory')).slice(-1 * 10)
  setItem('localDetailHistory', [...arr, data])
}

const useGetDetailHistory = (num = 10, isCart: 0 | 1 = 0) => {
  const [state, setState] = useState([])
  const location = useLocation()

  useEffect(() => {
    // console.log('location', location)
    const data = Type.array(getItem('localDetailHistory')).reverse()

    const uniqueIds = Knife.unique(data, (item) => item.skuId).map(
      (data) => data.skuId
    )

    const params: any = { skuIds: uniqueIds }
    isCart === 1 && (params.is_cart = 1)

    request.post('/product_flow/getFlowBySkuIds', params).then((res) => {
      setState(res.data?.productList || [])
    })
  }, [location.pathname])

  return state
}

/**
 * @description: 数组分组（一维数组转二维数组）
 * @param {any} arr 原数组
 * @param {number} size 多少一组
 * @return {array} 新二维数组
 */
const chunkArr = (arr: any[], size: number): [][] => {
  if (!arr?.length || !size || size < 1) return []
  let [start, end, result] = [null, null, []]
  for (let i = 0; i < Math.ceil(arr.length / size); i++) {
    start = i * size
    end = start + size
    result.push(arr.slice(start, end))
  }
  return result
}

const goToTop = (top: number = 0) => {
  setTimeout(() => {
    window &&
      window.scrollTo({
        top: top,
        behavior: 'smooth'
      })
  }, 250)
}

const isHomeProject = ['home', 'home-m'].includes(process.env.template)

const validateEmail = (val: string): boolean => {
  return /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/.test(val)
}

const compareObjectsResult = (
  obj1: object,
  obj2: object,
  excludedFields: any[]
) => {
  // 排除的字段列表转换为集合，以便快速查找
  const excludedSet = new Set(excludedFields)
  const keys1: any = new Set(Object.keys(obj1))
  const keys2: any = new Set(Object.keys(obj2))
  // 检查两个对象是否有相同的键
  if (!keys1.size === keys2.size) {
    return false
  }
  // 比较两个对象中剩余键的对应值
  for (const key of keys1) {
    if (excludedSet.has(key)) continue
    if (obj1[key] !== obj2[key]) {
      return false
    }
  }
  // 如果所有比较都通过，返回true
  return true
}

const isPluralString = (number: number | string) => {
  return Type.number(number) > 1 ? 's' : ''
}

// FB.init
export const fbInitExe = (opt: object) => {
  window.FB &&
    window.FB.init(
      Object.assign(
        {
          appId: '683308623231680',
          xfbml: true,
          version: 'v20.0'
        },
        opt || {}
      )
    )
  window.FB && window.FB.AppEvents.logPageView()
}

// fb sdk.js加载
export const fbSdkCallback = (appid: string, callback?: Function) => {
  const fbSDK = document.getElementById('facebook-jssdk')
  if (!fbSDK) {
    const src = 'https://connect.facebook.net/en_US/sdk.js'
    injectScript(
      src,
      () => {
        fbInitExe({
          appId: appid
        })
        callback && callback()
      },
      true,
      'facebook-jssdk',
      () => {
        console.error('Error loading:' + src)
      }
    )
  } else {
    fbInitExe({
      appId: appid
    })
    callback && callback()
  }
}

export const ggSdkCallback = (callback?: Function) => {
  const ggSDK = document.getElementById('google-js')
  if (!ggSDK) {
    const src = 'https://accounts.google.com/gsi/client?hl=en-US'
    injectScript(
      src,
      () => {
        callback && callback()
      },
      true,
      'google-js',
      () => {
        console.error('Error loading:' + src)
      }
    )
  } else {
    callback && callback()
  }
}

export {
  isPluralString,
  validateEmail,
  isHomeProject,
  pushDetailHistory,
  useGetDetailHistory,
  getAllSearchParams,
  getHVideoForm,
  isMobileProject,
  createdPortal,
  injectScript,
  parseXMLDataToJson,
  px2vw,
  px2rem,
  getMetaTitle,
  getMetaTitleNormal,
  getRelativeUrl,
  mockRequest,
  getSearchParams,
  setSearchParams,
  randomNum,
  isBrowser,
  isMobile,
  model,
  isLocalDev,
  throttle,
  debounce,
  loTrack,
  transTimeToDate,
  chunkArr,
  goToTop,
  compareObjectsResult
}
