import Event from '../khook/event/Event'
import Dataman from '../knife'
import genId from '../knife/genId'
import Type from '../type'

const createCtx = () => {
  let ctxData
  return {
    provider(data) {
      ctxData = data
    },
    get() {
      return ctxData
    }
  }
}

export class Processor {
  static id = genId('Processor')
  /**
   * 为对象延申一些属性
   * */
  static extend<T extends any, E extends Type.DefineObject>(
    target: T,
    props: E
  ) {
    //@ts-expect-error
    const proto = target.__proto__
    const superer: Type.DefineObject = { ...props }
    const copyTarget: Type.DefineObject = Dataman.assign(target)
    copyTarget.__proto__ = superer
    //@ts-expect-error
    superer.__proto__ = proto

    return copyTarget as T & E
  }
  /**
   * 一旦reject,then不会被兑现
   */
  static toPromise<T = any>(): Promise<T> & {
    resolve(r?: T): any
    reject(err?: any): any
    status: () => 'pending' | 'resolve' | 'reject'
    reset: () => any
  } {
    let resolve
    let reject
    let status = 'pending'

    const p: any = new Promise((_resolve, _reject) => {
      resolve = _resolve
      reject = _reject
    }).then(
      (e: any) => {
        // if (status === 'reject') {
        //   return
        // }
        status = 'resolve'
        // thenQueue.reduce((res, callback) => {
        //   const newRes = callback.resolve(res)
        //   return newRes
        // }, e)
        return e
      },
      (err) => {
        status = 'reject'
        // thenQueue.reduce((res, callback) => {
        //   const newRes = callback.reject?.(res)
        //   return newRes
        // }, err)
        return err
      }
    )

    p.resolve = (...arg) => {
      resolve(...arg)
      return p
    }

    p.reject = (err) => {
      reject(err)
      return p
    }

    p.status = () => status

    p.reset = () => (status = 'pending')

    return p
  }
  /**
   * 跳出当前执行栈
   * */
  static break() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true)
      })
    })
  }
  /**
   * 进程休眠
   * @param delay 延迟时间
   * */
  static sleep(delay = 0) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true)
      }, delay)
    })
  }
  /**
   * 创建可防抖的进程休眠方法
   * */
  static asleep() {
    const sleepAntiShakeTimer = Processor.timeout()
    return (delay = 0) => {
      return new Promise((resolve, reject) => {
        sleepAntiShakeTimer(() => resolve(true), delay)
        sleepAntiShakeTimer.shake(() => {
          reject()
        })
      }).catch(() => {})
    }
  }
  /**
   *  创建一个队列程序，程序完成任务收集进行批量操作
   */
  static awaitQueue<Task extends Type.DefineFunc>() {
    let count = 0
    let maxCount = 0
    const tasks: Task[] = []
    const event = Event()
    const rejectEvent = Event()
    //const caseEvent = Hook.Event();
    let getTaskRunningCondition = (count: number, maxCount: number) =>
      count >= maxCount

    let customTaskRunningCondition: typeof getTaskRunningCondition = () => false

    /**
     * 添加任务到队列,当任务收集结束通知run方法回调
     */
    const queue = (task: Task) => {
      maxCount = maxCount + 1
      tasks.push(task)
      new Promise<boolean>((resolve, reject) => {
        count = count + 1
        if (
          getTaskRunningCondition(count, maxCount) ||
          customTaskRunningCondition(count, maxCount)
        ) {
          count = 0
          maxCount = 0
          resolve(true)
          event.emit()
          rejectEvent.off()
          return
        }
        rejectEvent.emit()
        rejectEvent.only(() => {
          reject()
        })
      }).catch(() => {})

      return queue
    }

    /**
     * 任务收集结束的执行队列的方法; 执行之后清空队列
     * @example run promise 返回 task结果[]
     *   queue(task).run().then()
     *  指定义任务数量
     * queue(task).run(tasks=>tasks)
     */
    queue.run = function <T>(getTasks = (tasks: Task[]) => tasks) {
      return new Promise<T[]>((resolve) => {
        event.only(() => {
          const result = getTasks(tasks).map((task) => task())
          resolve(result)
        })
      }).then((res) => {
        event.off()
        tasks.length = 0
        return res
      })
    }

    /**
     * 自定义队列执行条件,默认队列收集程序不计数时执行
     * @example
     * queue.case(()=>true).run()
     */
    queue.case = function (
      resetTaskRunningCondition: typeof getTaskRunningCondition = () => false
    ) {
      customTaskRunningCondition = resetTaskRunningCondition
      return queue
    }
    return queue
  }
  static toexpander = Symbol('expander')
  /**
   * 函数装饰器 fn:被装饰函数,unit:修饰函数集合 {key:function},cache:是否缓存
   * */
  static expander<T extends Function>(
    fn: T,
    unit: ((raw: T) => (...arg) => any)[] | ((raw: T) => (...arg) => any),
    cache = true
  ): T {
    /* 倒叙插入 */
    const parts = [...(Type.array(unit) as any)]
    parts.sort(() => -1)

    /* 缓存装饰函数 */
    const agent: any = function (...arg) {
      if (cache === true) {
        agent.expander ||
          (agent.expander = Dataman.reduce(
            parts,
            (curRaw, part: any) => part(curRaw),
            fn
          ))
      } else {
        agent.expander = Dataman.reduce(
          parts,
          (curRaw, part: any) => part(curRaw),
          fn
        )
      }

      return agent.expander(...arg)
    }

    /* 非继承关系 */
    agent.prototype = fn.prototype
    //  fn[Processor.toexpander] = true
    return agent as any
  }
  /**
   * 为对象标记一个不可篡改不可操作的属性
   * */
  static mark<T extends object>(object: T, key, value: any = true) {
    const symbol = Processor.symbol(key)
    Object.defineProperty(object, symbol, {
      value: value,
      enumerable: false
    })
    return object
  }
  /**
   * 采用被mark函数标记过的属性
   * */
  static employMark(object: object, key) {
    if (Type.isNever(object)) {
      return
    }
    const symbol = Processor.symbol(key)
    return object?.[symbol]
  }
  /**
   * 获取一个特殊的字符串标识符,用于标识属性只读
   * */
  static symbol(key = ''): string {
    return `[[${Processor.id}.${key}]]`
  }
  static safaId = String(Math.random())
  /**
   * 获取一个特殊的字符串标识符,认为该字符不太可能被外部引用
   * */
  static safeKey(key = ''): string {
    return `safe(${key})#:${Processor.safaId}`
  }
  /**
   * 设置一个对象得属性为只读
   * */
  static readOnly(object: object, key: PropertyKey, value = '[[readOnly]]') {
    Reflect.defineProperty(object, key, {
      value: value,
      enumerable: false,
      configurable: false,
      writable: false
    })
    return object
  }
  /**
   * 设置一个对象的属性不可循环迭代
   * */
  static setPropOutLoop(
    object: object,
    key: PropertyKey,
    otherConfig: PropertyDescriptor = {}
  ) {
    const isDefine = Reflect.defineProperty(object, key, {
      ...otherConfig,
      enumerable: false
    })
    if (!isDefine) {
      // console.error(Dataman.joinStr('setPropOutLoop设置未成功在', key, '目标对象为:')('#'), object)
    }
    return object
  }
  /**
   * 设置一个对象不可变但是可以被枚举
   * */
  static immutable(object: object, key: PropertyKey, value = '[[immutable]]') {
    Reflect.defineProperty(object, key, {
      value,
      enumerable: true,
      configurable: false,
      writable: false
    })
    return object
  }
  /**
   * 调试方法,在异常情况开启调试方法
   * @param targetProcess 目标进程
   * @param debuggerProcess 错误时调试进程
   */
  static debugger(targetProcess: Function, debuggerProcess?: Function) {
    try {
      return targetProcess()
    } catch (error) {
      new Promise(() => {
        throw new Error(error)
      })
      return debuggerProcess?.()
    }
  }
  /**
   * 防抖计时器
   * @returns
   */
  static timeout() {
    let timer: NodeJS.Timeout | null
    let shakeCallback: Function
    /**
     * 计时器回调函数
     * @param callback
     * @param delay 延迟时间
     */
    const t = (callback: Type.DefineFunc, delay = 0) => {
      if (timer) {
        shakeCallback?.()
        clearTimeout(timer)
      }
      timer = setTimeout(() => {
        const result = callback()
        onRun?.(result)
        //clearTimeout(timer);
        timer = null
      }, delay)
    }

    let onRun
    t.onRun = (callback) => {
      onRun = callback
    }
    /**
     * 暂停正在进行的计时器
     */
    t.stop = () => {
      if (timer) clearTimeout(timer)
      timer = null
    }
    /**
     * 判断当前计时器是否在运行
     * @returns
     */
    t.isRun = () => (Type.isNumber(timer) ? true : false)
    /**
     * 计时器发生抖动时的回调
     * @param callback
     */
    t.shake = (callback: Function) => {
      shakeCallback = callback
    }
    return t
  }
  /**
   * 节流计时器
   * @returns
   */
  static throttle() {
    let open = true
    let timerAct = Processor.timeout()
    const t = (fn, delay = 0) => {
      if (open === true) {
        open = false
        fn()
        timerAct(() => {
          open = true
          //  t(fn, delay);
        }, delay)
      }
    }
    t.stop = () => {
      open = true
      timerAct.stop()
    }
    return t
  }
}

export default Processor
