import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router'

import Switch from '@ui-pc/Switch'
import { getMetaTitle, getMetaTitleNormal, getSearchParams } from '@utils'

/**
 * @todo no function
 */
const useNoSSR = (fn: () => Function, defaultVal?) => {
  const [state, useState] = React.useState<any>(false)

  React.useEffect(() => {
    useState(true)
  }, [])

  return state ? fn() : defaultVal
}

/**
 * 解决水合失败问题
 * Hydration failed
 */
export const NoSSR = (props: any) => {
  const [state, useState] = React.useState<any>(false)
  React.useEffect(() => {
    useState(true)
  }, [])

  return state ? props.children : <></>
}

/**
 * 为目标注入no ssr组件
 */
export const NoSSRHOC = function <T extends React.FC<any>>(Fn: T) {
  return (props: React.ComponentProps<T>) => {
    return (
      <NoSSR>
        <Fn {...props} />
      </NoSSR>
    )
  }
}

const webTitle = {
  ['two']: 'Hernest',
  ['hernest-m']: 'Hernest',
  ['home']: 'Yitahome',
  ['home-m']: 'Yitahome'
}

export const metaTitle = webTitle[process.env.template]

const isHe = ['two', 'hernest-m'].includes(process.env.template)

const customCb = (location) => {
  let regex = /\/(.+)\/(.+)?/

  let [_, title] = location.pathname?.match(regex) || ['', '']

  if (location.pathname.indexOf('/search') > -1) {
    const params = getSearchParams(location.search, ['keywords', 'q'] as const)
    title = params.keywords || params.q || 'search'
  } else if (
    /^(\/flashDeal|\/hot|\/new|\/new-arrivals)$/.test(location.pathname)
  ) {
    title = location.pathname.split('/')?.[1]
  } else if (location.pathname.indexOf('/custompro/') > -1) {
    title = 'custompro'
  }
  return title
}

export const ProductMetaTitle = (data: any) => {
  const { title, keyword, description } = data || {}
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="keywords" content={keyword} />
      <meta name="description" content={description} />
    </Helmet>
  )
}

// 搜索页、flash deal等
export const getProductOtherMetaInfo = (pathname, href) => {
  const queryUrl = (href && href.split('?')?.[1]) || ''
  let titleStr = ''
  if (pathname.indexOf('/search') > -1) {
    const params = getSearchParams(queryUrl, ['keywords', 'q'] as const)
    titleStr = params.keywords || params.q || 'search'
  } else if (/^\/flashDeal$/.test(pathname)) {
    titleStr = isHe
      ? 'Flash Sale | Final hours for big discounts'
      : 'Flash Sale - Final hours for big discounts'
  } else if (/^(\/hot|\/new|\/new-arrivals)$/.test(pathname)) {
    titleStr = pathname.split('/')?.[1]
  }
  titleStr = getMetaTitleNormal(titleStr)
  const newData = {
    metaInfo: {
      title: isHe ? titleStr + ' - ' + metaTitle : metaTitle + ' | ' + titleStr,
      keyword: titleStr,
      description: titleStr
    }
  }
  return newData
}

/**
 * 设置meta title
 */
export const MetaHoc = (FC: React.FC, customCb?: Function) => {
  return function Title(props) {
    const location = useLocation()
    const title =
      (customCb && getMetaTitleNormal(customCb(location))) ||
      getMetaTitle(location.pathname + location.search)
    return (
      <>
        <Helmet>
          {isHe ? (
            <title>
              {' '}
              {title} - {metaTitle}
            </title>
          ) : (
            <title>
              {' '}
              {metaTitle} | {title}
            </title>
          )}
        </Helmet>
        <FC {...props} />
      </>
    )
  }
}

MetaHoc.DetailTitle = (props) => {
  const { title = 'products' } = props
  return (
    <Helmet>
      isHe ? (
      <title>
        {title} - {metaTitle}
      </title>
      ) : (
      <title>
        {metaTitle} | {title}
      </title>
      )
    </Helmet>
  )
}

// 通用meta标签
export const CommonMeta = (data: any) => {
  const { metaKeywords, metaDescription, publisher } = data || {}
  return (
    <Helmet>
      <title>{metaTitle + `${isHe ? ' - ' : ' | '}` + data?.metaTitle}</title>
      <meta name="keywords" content={metaKeywords} />
      <meta name="description" content={metaDescription} />
      <meta name="author" content={publisher} />
    </Helmet>
  )
}
// 通用fb meta标签
export const FbCommonMeta = (data: any) => {
  const { fbtitle, fburl, fbImg, fbDescription } = data || {}
  return (
    <Helmet>
      <meta property="og:type" content="website" />
      <meta property="og:description" content={fbDescription} />
      <meta property="og:site_name" content={metaTitle}></meta>
      <meta name="og:title" content={fbtitle} />
      <meta name="og:url" content={fburl} />
      <meta name="og:image" content={fbImg} />
      <meta name="og:image:secure_url" content={fbImg} />
    </Helmet>
  )
}

export type TConnect<
  F extends Type.DefineFunc<Type.DefineObject>,
  P extends Type.DefineObject = {}
> = Partial<ReturnType<F>> & P

/**
 * 隔离页面逻辑和ui,防止双端产生冗余的文件
 */
export const connect = function F<F extends Type.DefineFunc<Type.DefineObject>>(
  useHook: F
) {
  return function Index<T>(FC: React.FC<T>) {
    return function P(props: T) {
      return <FC {...props} {...useHook(props)} />
    }
  }
}
