import { request } from '@utils/request'

import { HomeType } from './types'

/**
 * 首页数据
 * @types-Home
 * getHome created by Home api
 */
export const getHome = async (params: { terminal: string; page: string }) => {
  return request.get<HomeType>('/page/detail', params).then((res) => {
    return res.data
  })
}
/* @types-Home */
