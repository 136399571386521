import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { request } from '@utils/request'

export interface searchInputState {
  keywords: string,
  autoCompleteList: any[]
}

const initialState: searchInputState = {
  keywords: '',
  autoCompleteList: []
}

export const searchInputSlice = createSlice({
  name: 'searchInput',
  initialState,
  reducers: {
    updateKeywords: (state, action: PayloadAction<string>) => {
      state.keywords = action.payload
    },
    updateAutoCompleteList: (state, action: PayloadAction<any[]>) => {
      state.autoCompleteList = action.payload
    }
  }
})
export const getSearchSuggest = async (params) => {
  const res: any = await request.get(`/category/getSearchSuggest`, params)
  return res
}
export const { updateKeywords, updateAutoCompleteList } = searchInputSlice.actions

export default searchInputSlice.reducer


