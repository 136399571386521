/**
 * 获取唯一id
 * */
function genId(prefix: string = '') {
  //default
  IDBOX[prefix] || (IDBOX[prefix] = 0)

  if (IDBOX[prefix] < Number.MAX_SAFE_INTEGER) {
    return prefix + IDBOX[prefix]++
  }
  return prefix + IDBOX[prefix] + Date.now()
}

const IDBOX: any = {
  '': 0
}

export default genId
