import React from 'react'

import { Type } from '@utils/tool'

type SwitchProps = {
  children?:
    | [React.ReactNode, React.ReactNode]
    | [React.ReactNode]
    | React.ReactNode
  either: boolean
}
/**
* 条件渲染组件,最多允许存在两个子节点,either为true渲染第一个,否则渲染第二个
  @example 渲染span.abc区域
  <Switch either={true}>
  <Switch either={true}><span>abc</span></Switch>
  <Switch either={false}><span>qwe</span></Switch>
</Switch>
*/
const Switch = (props: SwitchProps) => {
  const { either, children } = props
  const wrapChildren = Type.array(children, [children]) as any[]
  const [left, right] = wrapChildren
  if (wrapChildren?.length > 2) {
    throw new Error('A maximum of two child nodes of the switch are allowed')
  }
  return <>{either ? left : right}</>
}

export const Swap = (props: SwitchProps) => {
  const { either, children } = props
  const wrapChildren = Type.array(children, [children]) as any[]
  const [left, right] = wrapChildren
  if (wrapChildren?.length > 2) {
    throw new Error('A maximum of two child nodes of the switch are allowed')
  }
  return either ? (
    <>
      {right}
      {left}
    </>
  ) : (
    <>
      {left}
      {right}
    </>
  )
}

export default Switch
