import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { request } from '@utils/request'
import { setItem } from '@utils/storage'

export interface AppState {
  isLogin: boolean
  userInfo: any
  topAd: any[]
  menu: any[]
  secondaryMenu: any[]
  footerLink: any[]
  yotpoAppKey: string
  newUserCouponPackAmount: string // 新人券包总额
  turntable: any // 转盘抽奖数据
  logoPath: string
  lcLogoPath: string // 透明logo
  footerPath: string
  backgroundPath: string
  couponInfo
  subscriptionText
  currentAddCartCount: number // 当前加购数量计算
  currentAddCartSkuIds: string[] // 当前加购的 SkuIds
  wishTotal: number // 收藏数量
  refreshFavorites: number // 刷新收藏列表页
}

const initialState: AppState = {
  isLogin: false,
  userInfo: {},
  topAd: [],
  menu: [],
  secondaryMenu: [],
  footerLink: [],
  yotpoAppKey: '',
  newUserCouponPackAmount: '',
  turntable: {},
  logoPath: '',
  lcLogoPath: '',
  footerPath: '',
  backgroundPath: '',
  couponInfo: {},
  subscriptionText: {},
  currentAddCartCount: 0,
  currentAddCartSkuIds: [],
  wishTotal: 0,
  refreshFavorites: null
}

export const AppSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    updateUserInfo: (state, action: PayloadAction<any>) => {
      state.userInfo = action.payload
      state.isLogin = !!action.payload?.customersId
    },
    updateCouponInfo(state, action: PayloadAction<any>) {
      state.couponInfo = action.payload
    },
    updateSubscriptionText(state, action: PayloadAction<any>) {
      state.subscriptionText = action.payload
      // setItem('subscriptionText', action.payload)
    },
    updateCurrentAddCartCount(state, action: PayloadAction<any>) {
      state.currentAddCartCount = action.payload
    },
    updateCurrentAddCartSkuIds(state, action: PayloadAction<any>) {
      state.currentAddCartSkuIds = action.payload
    },
    updateWishTotal(state, action: PayloadAction<number>) {
      state.wishTotal = action.payload
    },
    updateRefreshFavorites(state, action: PayloadAction<number>) {
      state.refreshFavorites = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCommonData.fulfilled, (state, action) => {
      const {
        top,
        nav,
        secondary,
        bottom,
        yotpo,
        coupon,
        turntable,
        logoPath,
        lcLogoPath,
        footerPath,
        backgroundPath,
        wishTotal = 0
      } = action.payload.data
      state.topAd = top || []
      state.menu = nav || []
      state.secondaryMenu = secondary || []
      state.footerLink = bottom || []
      state.yotpoAppKey = yotpo?.appKey || ''
      state.newUserCouponPackAmount = coupon?.newUserCouponPackAmount
      state.turntable = turntable || {}
      state.logoPath = logoPath
      state.lcLogoPath = lcLogoPath
      state.footerPath = footerPath
      state.backgroundPath = backgroundPath
      // state.wishTotal = wishTotal
    })
  }
})

export const {
  updateUserInfo,
  updateCouponInfo,
  updateSubscriptionText,
  updateCurrentAddCartCount,
  updateCurrentAddCartSkuIds,
  updateWishTotal,
  updateRefreshFavorites
} = AppSlice.actions

// 用于检查用户身份信息
export const getCustomerInfo = async () => {
  return request
    .get('/customers/getCustomerInfo')
    .then((res) => {
      return res
    })
    .catch((err) => {
      return err
    })
}

/**
 * @description: 获取公共数据（顶部广告/导航菜单/页脚链接）
 * @return {*}
 */
export const fetchCommonData: any = createAsyncThunk(
  'app/getCommonData',
  async () => await request.get('/nav/getMainNav', { terminal: 'pc' })
)

export default AppSlice.reducer
