import Cookies from 'js-cookie'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { inBrowser } from '../utils'

export default function () {
  if (!inBrowser) {
    return
  }
  const location = useLocation()

  useEffect(() => {
    Cookies.set('referrer', `${location.pathname}${location.search}`)
  }, [location])
}
