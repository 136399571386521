import { inBrowser } from './'

/**
 * @description: 储存数据
 * @param {string} key
 * @param {any} value
 * @return {*}
 */
export const setItem = (key: string, value: any) => {
  if (!inBrowser) {
    return null
  }

  if (typeof value === 'object') {
    value = JSON.stringify(value)
  }
  window.localStorage.setItem(key, value)
}

/**
 * @description: 获取指定数据
 * @param {string} key
 * @return {*}
 */
export const getItem = (key: string): any => {
  if (!inBrowser) {
    return null
  }

  const data: any = window.localStorage.getItem(key)
  if (data === 'undefined' || data === 'null') {
    removeItem(key)
    return null
  }
  try {
    return JSON.parse(data)
  } catch (error) {
    return data
  }
}

/**
 * @description: 删除指定数据
 * @param {string} key
 * @return {*}
 */
export const removeItem = (key: string): void => {
  if (!inBrowser) return
  window.localStorage.removeItem(key)
}

/**
 * @description: 删除所有数据
 * @return {*}
 */
export const removeAllItem = () => {
  if (!inBrowser) return
  window.localStorage.clear()
}
