import { Progress } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  matchRoutes,
  Outlet,
  RouteMatch,
  RouteObject,
  useLocation
} from 'react-router'

import { useNodeMutation, useSearch } from '@hooks'
import useInjectUnexpectedError from '@hooks/useInjectUnexpectedError'
import useRouteAfterEach from '@hooks/useRouteAfterEach'
import { useSubscription } from '@ui-pc/Subscription'
import { isHomeProject } from '@utils/index'
import { request } from '@utils/request'
import { NoSSR } from '@utils/SSR'
import { Knife, Type } from '@utils/tool'

import { IRoute } from './types'
import Cookies from 'js-cookie'

// import TopAd from '@/home/src/components/TopAd'
// import Header from '@/home/src/components/Header'
// import Footer from '@/home/src/components/Footer'

const TopAd = React.lazy(
  () => import(`@/${process.env.template}/src/components/TopAd`)
)

const Header = React.lazy(
  () => import(`@/${process.env.template}/src/components/Header`)
)

const Footer = React.lazy(
  () => import(`@/${process.env.template}/src/components/Footer`)
)

const isHE = ['two', 'hernest-m'].includes(process.env.template)

export const Progresser = (props) => {
  const { isLoading, strokeColor } = props
  const [num, useNum] = useState(Knife.randomRx(5, 20))
  const [show, useShow] = useState(true)
  React.useEffect(() => {
    if (isLoading === false) {
      useNum(100)
      setTimeout(() => {
        useShow(false)
      }, 100)
    }
  }, [isLoading])

  React.useEffect(() => {
    if (isLoading === false) return
    const timer = setTimeout(
      () => {
        useNum(Math.min(90, num + Knife.randomRx(0, 50)))
      },
      50 + num * 2.5
    )
    return () => {
      clearTimeout(timer)
    }
  }, [num])

  return (
    show && (
      <Progress
        style={{
          marginInlineEnd: 0,
          margin: 0,
          position: 'fixed',
          width: '100%',
          height: '3px',
          zIndex: 999,
          overflow: 'hidden'
        }}
        strokeColor={isHE ? '#222' : '#157e6f'}
        percent={num}
        size="small"
        strokeLinecap="butt"
        status="active"
        showInfo={false}
      />
    )
  )
}

const Enter = (props) => {
  const [isLoading, useLoading] = useState(true)
  const location = useLocation()

  useRouteAfterEach()

  const ref = useNodeMutation(() => {
    useLoading(false)
  })

  useEffect(() => {
    window &&
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
  }, [location.pathname])

  return (
    <>
      <NoSSR>
        <Progresser isLoading={isLoading} strokeColor={props.strokeColor} />
      </NoSSR>
      <div style={{ opacity: isLoading ? 0 : 1 }}>
        <TopAd />
        <Header />
        <div
          ref={(el) => {
            ref.current.el = el
          }}>
          <Outlet {...props} />
        </div>
        <Footer />
      </div>
      {/* {!isLoading && props.siderTip} */}
    </>
  )
}

/**
 * home目录基准容器
 */
export const HomeBase = (props) => {
  const [isLoading, useLoading] = useState(true)
  const location = useLocation()
  const siderTip = useSubscription()
  // 列表页集合
  const conditions = [
    location.pathname?.includes('/category/'),
    location.pathname?.includes('/c/'),
    location.pathname?.includes('/search'),
    location.pathname?.includes('/custompro/'),
    location.pathname?.includes('/best-sellers/'),
    location.pathname?.includes('/flashDeal'),
    location.pathname?.includes('/new-arrivals')
  ]
  useRouteAfterEach()

  const ref = useNodeMutation(() => {
    useLoading(false)
  })

  useEffect(() => {
    // he+home的M端回退定位: 列表页和详情页不需要清空cookie, 否则要清除掉
    const cookieParams = Cookies.get('recordProductCookie') || {}
    if (
      !conditions.some((condition) => condition) &&
      location.pathname.indexOf('/products/') === -1 &&
      JSON.stringify(cookieParams) !== '{}'
    ) {
      Cookies.remove('recordProductCookie')
    }
    if (!conditions.some((condition) => condition)) {
      window &&
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
    }
  }, [location.pathname])

  const isItem = location.pathname?.indexOf?.('/item/') > -1

  return (
    <>
      <NoSSR>
        <Progresser isLoading={isLoading} strokeColor={props.strokeColor} />
      </NoSSR>
      <div style={{ opacity: isLoading ? 0 : 1 }}>
        <TopAd />
        <Header />
        <div
          ref={(el) => {
            ref.current.el = el
          }}>
          <Outlet {...props} />
        </div>
        <Footer />
      </div>
      {!isLoading && !isItem && siderTip}
    </>
  )
}

/**
 * 过渡动画高阶组件
 */
export const TransitionHoc = (FC) => {
  return function Index(props) {
    const [isLoading, useLoading] = useState(true)

    useRouteAfterEach()

    const ref = useNodeMutation(() => {
      useLoading(false)
    })

    return (
      <>
        <NoSSR>
          <Progresser isLoading={isLoading} />
        </NoSSR>
        <div
          ref={(el) => {
            ref.current.el = el
          }}
          style={{ opacity: isLoading ? 0 : 1 }}>
          <FC {...props} />
        </div>
      </>
    )
  }
}

/**
 *
 * 路由增强组件,处理和代理预请求信息.
 * 数据会根据路由信息存贮在store里
 */
export const ElementEnhance = (
  route: IRoute,
  parent = {} as IRoute,
  routes: IRoute[] = []
) => {
  let { element: FC, action, initialState, matchquery, index, path } = route

  // if (index) {
  //   action = parent.action
  //   initialState = parent.initialState
  // }

  const importable = (props: any) => {
    const location = useLocation()

    const dispatch = useDispatch()

    const serverData = useSelector(
      (state: any) => state.page?.[location.pathname] || {}
    )

    const { mutation, isLoading, isError } = useSearch(
      //@ts-expect-error
      () => action(request, location.pathname, window?.location),
      ['mutation']
    )

    const requestFromClient = () => {
      return mutation()
        .then((data) => {
          dispatch({
            type: 'page/update',
            payload: {
              [location.pathname]: {
                data: data || initialState,
                isPreAction: 'fromClient',
                status: true
              }
            }
          })
        })
        .catch((err) => {
          dispatch({
            type: 'page/update',
            payload: {
              [location.pathname]: {
                data: initialState,
                isPreAction: 'fromClient',
                status: false
              }
            }
          })
        })
    }

    const ref = React.useRef({ path: {} })
    React.useEffect(() => {
      if (!action) return
      const isExist =
        !matchquery || ref.current.path[location.pathname + location.search]
      //服务端有数据时不请求
      if (
        serverData?.isPreAction === 'fromServer' &&
        serverData?.status &&
        isExist
      )
        return

      if (serverData?.isPreAction === 'fromClient' && isExist) return

      //路由匹配
      const matchedRoutes = Knife.get(
        () => matchRoutes(routes as any, location.pathname).pop(),
        {}
      )

      const isCurrentApp = matchedRoutes?.route?.path === path

      if (!isCurrentApp) return

      //console.log('refresh,.....', serverData)

      requestFromClient()
      ref.current.path[location.pathname + location.search] = true
    }, [location.pathname, location.search])
    return (
      <FC
        {...props}
        action={requestFromClient}
        isLoading={isLoading}
        isError={isError}
        requestStatus={serverData?.status}
        pageData={serverData?.data || initialState}
      />
    )
  }

  importable.loadData = (FC as any).loadData

  return importable
}

/**
 *
 * 获取储存在store里面的预请求数据
 * @param pathname 路由信息
 */
export const getPreActionData = (pathname: string) => {
  const { data } = useSelector((state: any) => state.page[pathname] || {})
  return data
}

/**
 * root根路径用于 usenavigate 跳转
 */

export const routesPath = {
  home: '/',
  collection: '/collection',
  category: '/collection/c',
  custom: '/collection/s',
  new: '/new',
  hot: '/hot',
  newArrivals: '/new-arrivals',
  flashDeal: '/flashDeal',
  search: '/search',
  custompro: '/custompro',
  bestSellers: '/best-sellers',
  product: '/products',
  activity: '/activity',
  cart: '/cart',
  checkout: '/checkout',
  statement: '/statement',
  user: '/user',
  order: '/order',
  password: '/user/password',
  trackOrder: '/track',
  trackOrderDetail: '/trackDetail',
  articleDetail: '/article',
  aboutYitahome: '/about-yitahome',
  productCare: '/product-care',
  sustainability: '/sustainability',
  affiliateProgram: '/affiliate-program',
  categoryEntry: '/categoryEntry',
  login: '/login',
  whyUs: '/why-us',
  contactUs: '/help',
  ourStory: '/our-story',
  item: '/item',
  quickView: '/quickView',
  paymentWaiting: '/payment-waiting'
} as const

export const mapRoutes = (routes: IRoute[], parent?: IRoute): RouteObject[] => {
  return routes.map((route) => {
    const Ele = ElementEnhance(route, parent, routes)

    const { index, path, ...rest } = route

    const nextPath: any = index ? { index, ...rest } : { path, ...rest }

    const enhanceRoute: RouteObject = {
      ...nextPath,
      element: <Ele />
    }
    if (route.children) {
      enhanceRoute.children = mapRoutes(route.children, route)
    }

    return enhanceRoute
  })
}
