import { setItem, getItem } from '@utils/storage'
import Cookies from 'js-cookie'

let fbe = ''
try {
  if (window.fa && window.fa.uuid) {
    fbe = window.fa.uuid()
    Cookies.set('_fbe', fbe, { expires: 3650 })
  }
} catch (error) {}

type product = {
  sku: string // 商品 sku
  status: number | string // 商品状态
  poa: string // poa
  pid: string // 商品 pid*
  name?: string // 商品名称* viewItemList不需要产品名称
  currency: string // 币种
  finalPrice: number // 最终价格
  discount: string // 折扣百分比
  qty?: number | string // 数量
  categories: string // 类目
}

function trigger(eventName: string, data?: any): void {
  try {
    window.fa && window.fa.en(eventName, data)
  } catch (err) {
    console.error(`${eventName}: ${err}`)
  }
}

function createShareASaleImg(data): void {
  const skuList = []
  const priceList = []
  const quantity = []
  data.ordersProducts.forEach((item) => {
    skuList.push(item.poa)
    priceList.push(item.finalPrice)
    quantity.push(item.qty)
  })

  const shareASaleImg = document.createElement('img')
  shareASaleImg.src = `https://www.shareasale.com/sale.cfm?tracking=${
    data?.sgOrderId
  }&amount=${data?.otTotalValue || data?.otTotal}&couponcode=${
    data.couponCode
  }&skulist=${skuList.join(',')}&pricelist=${priceList.join(
    ','
  )}&quantitylist=${quantity.join(
    ','
  )}&currency=USD&merchantID=107344&v=dev1.5&transtype=sale`
  shareASaleImg.id = '_SHRSL_img_1'
  shareASaleImg.width = 1
  shareASaleImg.height = 1
  document.getElementsByTagName('body')[0].appendChild(shareASaleImg)
}

/**
 * @description: 自定义埋点
 * @param {string} action 事件行为
 * @param {string} label 事件标签
 * @param {string} value 事件价值
 * @param {*} params 自定义参数
 * @return {*}
 */
function custom(action: string, label?: string, value?: string, params = {}) {
  try {
    window.fa && window.fa.event('engagement', action, label, value, params)
  } catch (error) {}
}

/**
 * @description: 曝光广告图
 * @param {string} page 页面缩写
 * @param {string} module 模块名称
 * @param {any}    data   当前banner位数据
 * @param {any}    images 当前banner图的数据
 * @param {string} index 索引值（第几个元素）
 * @param {'0'|'1'} isClick  是否点击
 * @return {*}
 */
function viewSection(
  page: string,
  module: string,
  data: any,
  images: any,
  index: string | number,
  isClick: '0' | '1' = '0',
  custom: any = null
) {
  const pageName =
    typeof page === 'undefined'
      ? ''
      : page === '' || page === 'Home'
        ? 'Home'
        : page.slice(0, 1).toUpperCase() + page.slice(1)
  const productList: product[] = []
  const items = !!custom
    ? custom
    : {
        bannersId: data?.bannersId,
        bannersTitle: data?.bannersTitle,
        bannersPage: data?.bannersPage,
        bannersPosition: data?.bannersPosition,
        bannersTerminal: data?.bannersTerminal,
        bannersUrl: data?.bannersUrl,
        bannersImage: images
      }
  const obj: { [key: string]: any } = {
    page: pageName,
    module: module || data?.bannersTitle || data?.bannersPosition || '',
    index: index + '',
    items,
    isClick
  }
  if (isClick === '1') {
    obj._mixin = {
      location: window?.location?.href
    }
  }

  trigger('ViewSection', obj)
}

/**
 * @description: 曝光广告图
 * @param {string} page 页面缩写
 * @param {string} module 模块名称
 * @param {any}    data   当前banner位数据
 * @param {any}    images 当前banner图的数据
 * @param {string} index 索引值（第几个元素）
 * @param {'0'|'1'} isClick  是否点击
 * @return {*}
 */
function ViewSectionForExposure(
  page: string,
  module: string,
  data: any,
  images: any,
  index: string | number,
  isClick: '0' | '1' = '0',
  custom: any = null
) {
  const pageName =
    typeof page === 'undefined'
      ? ''
      : page === '' || page === 'Home'
        ? 'Home'
        : page.slice(0, 1).toUpperCase() + page.slice(1)
  const productList: product[] = []
  const items = !!custom
    ? custom
    : {
        bannersId: data?.bannersId,
        bannersTitle: data?.bannersTitle,
        bannersPage: data?.bannersPage,
        bannersPosition: data?.bannersPosition,
        bannersTerminal: data?.bannersTerminal,
        bannersUrl: data?.bannersUrl,
        bannersImage: images
      }
  const obj: { [key: string]: any } = {
    page: pageName,
    module: module || data?.bannersTitle || data?.bannersPosition || '',
    index: index + '',
    items,
    isClick
  }
  if (isClick === '1') {
    obj._mixin = {
      location: window?.location?.href
    }
  }

  const eventName = 'ViewSection'
  try {
    window.fa && window.fa.ex(eventName, obj)
  } catch (err) {
    console.error(`${eventName}: ${err}`)
  }
}

/**
 * @description: 曝光产品
 * @param {any} item 产品数据
 * @param {string} page 所属页面
 * @param {string} module 所属模块
 * @return {*}
 */
function viewItem(item: any, page: string, module: string): void {
  const pageName =
    typeof page === 'undefined'
      ? ''
      : page === ''
        ? 'Home'
        : page.slice(0, 1).toUpperCase() + page.slice(1)

  const productList: product[] = [
    {
      sku: item.productsSkuId, // 商品 sku
      status: item.productsStatus, // 商品状态
      poa: item.poa, // poa
      pid: item.productsId, // 商品 pid*
      name: item.productsName, // 商品名称*
      currency: 'USD', // 币种
      finalPrice: item.finalPrice, // 最终价格
      discount: '', // 折扣百分比
      categories: item?.categoriesNames || ''
    }
  ]
  trigger('ViewItem', {
    page: pageName,
    module: module || '',
    items: productList,
    _mixin: {
      location: window?.location?.href
    }
  })

  try {
    const categoryArr = item?.categoriesNames?.split('/') || []
    window.gtag &&
      window.gtag('event', 'view_item', {
        currency: 'USD',
        value: item.finalPrice || '',
        items: [
          {
            item_id: item.productsSkuId || item.productsId,
            item_name: item.productsName || '',
            // affiliation: "Google Merchandise Store",
            // coupon: "SUMMER_FUN",
            // discount: 2.22,
            // index: 0,
            // item_brand: "Google",
            item_category: categoryArr[0] || '',
            item_category2: categoryArr[1] || '',
            item_category3: categoryArr[2] || '',
            item_category4: categoryArr[3] || '',
            item_category5: categoryArr[4] || '',
            // item_list_id: "related_products",
            // item_list_name: "Related Products",
            // item_variant: "green",
            // location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
            price: item.finalPrice || '',
            quantity: 1
          }
        ]
      })

    window.gtag &&
      window.gtag('event', 'conversion', {
        send_to: 'AW-11138563565/8iAiCO_Gl5cYEO37o78p'
      })
    window.gtag &&
      window.gtag('event', 'conversion', {
        send_to: 'AW-11327363858/LB9PCIHW7vYYEJK2p5kq'
      })

    window.pintrk &&
      window.pintrk('track', 'pagevisit', {
        event_id: Date.now()
      })

    pLoad &&
      pLoad.product(
        item.productsSkuId || item.productsId,
        item?.categoriesNames
      )
  } catch (error) {
    console.error(`ViewItem: ${error}`)
  }
}

/**
 * @description: 列表曝光埋点
 * @param {any} list
 * @return {*}
 */
export type ViewItemListPage =
  | 'Home'
  | 'Category'
  | 'Search'
  | 'Activity'
  | 'Products'

function viewItemList(
  list: any[],
  page: ViewItemListPage | string,
  module
): void {
  let productList: product[] = []
  list.map((item) => {
    productList.push({
      sku: item.productsSkuId, // 商品 sku
      status: item.productsStatus, // 商品状态
      poa: item.poa, // poa
      pid: item.productsId, // 商品 pid*
      // name: item.productsName, // 商品名称*
      currency: 'USD', // 币种
      finalPrice: item.finalPrice, // 最终价格
      discount: '', // 折扣百分比
      categories: item?.categoriesNames || ''
    })
  })

  trigger('ViewItemList', {
    page,
    module,
    items: productList
  })
}

/**
 * @description: 收藏
 * @param {any} item
 * @return {*}
 */
function addToWishlist(item: any): void {
  const eventName = 'AddToWishlist'
  const productList: product[] = [
    {
      sku: item.productsSkuId, // 商品 sku
      status: item.productsStatus, // 商品状态
      poa: item.poa, // poa
      pid: item.productsId, // 商品 pid*
      name: item.productsName, // 商品名称*
      currency: 'USD', // 币种
      finalPrice: item.usPrice || item.finalPrice, // 最终价格
      discount: '', // 折扣百分比
      categories: item?.categoriesNames || ''
    }
  ]
  const price = item.usPrice || item.finalPrice
  try {
    window.fa &&
      window.fa.en(eventName, {
        items: productList
      })

    // GA4
    window.gtag &&
      window.gtag('event', 'add_to_wishlist', {
        currency: 'USD',
        value: price,
        items: [
          {
            item_id: item.productsSkuId,
            item_name: item.productsName,
            // affiliation: 'Google Merchandise Store',
            // coupon: 'SUMMER_FUN',
            // discount: 2.22,
            // index: 0,
            // item_brand: 'Google',
            // item_category: 'Apparel',
            // item_category2: 'Adult',
            // item_category3: 'Shirts',
            // item_category4: 'Crew',
            // item_category5: 'Short sleeve',
            // item_list_id: 'related_products',
            // item_list_name: 'Related Products',
            // item_variant: 'green',
            // location_id: 'ChIJIQBpAG2ahYAR_6128GcTUEo',
            price: price,
            quantity: 1
          }
        ]
      })

    // fb
    window.fbq &&
      window.fbq(
        'track',
        'AddToWishlist',
        {
          currency: 'USD',
          value: price,
          content_name: item.productsName,
          content_category: '', // 页面名称或产品分类
          content_ids: [item.productsId],
          contents: [
            {
              id: item.productsId,
              quantity: 1
            }
          ]
        },
        { eventID: fbe }
      )

    window.ttq &&
      window.ttq.track('AddToWishlist', {
        contents: [
          {
            content_id: item.productsId, // string. ID of the product. Example: "1077218".
            content_type: 'product', // string. Either product or product_group.
            content_name: item.productsName // string. The name of the page or product. Example: "shirt".
          }
        ],
        value: 1, // number. Value of the order or items sold. Example: 100.
        currency: 'USD' // string. The 4217 currency code. Example: "USD".
      })
  } catch (err) {
    console.error(`${eventName}: ${err}`)
  }
}

/**
 * @description: 加购
 * @param {string} routeName
 * @param {any} item
 * @param {any} params 自定义参数
 * @return {*}
 */
function addToCart(
  routeName: string,
  item: any,
  count?: number,
  params: { [key: string]: any } = {}
): void {
  const eventName = 'AddToCart'
  let productList: product[] = []
  if (Array.isArray(item)) {
    item.map((e) => {
      productList.push({
        sku: e.productsSkuId || '', // 商品 sku
        status: e.productsStatus || '', // 商品状态
        poa: e.poa || '', // poa
        pid: e.productsId || '', // 商品 pid*
        name: e.productsName || '', // 商品名称*
        currency: 'USD', // 币种
        finalPrice: e.usPrice || e.finalPrice || '', // 最终价格
        discount: '', // 折扣百分比
        categories: e?.categoriesNames || ''
      })
    })
  } else {
    productList = [
      {
        sku: item.productsSkuId || '', // 商品 sku
        status: item.productsStatus || '', // 商品状态
        poa: item.poa || '', // poa
        pid: item.productsId || '', // 商品 pid*
        name: item.productsName || '', // 商品名称*
        currency: 'USD', // 币种
        finalPrice: item.usPrice || item.finalPrice || '', // 最终价格
        discount: '', // 折扣百分比
        categories: item?.categoriesNames || ''
      }
    ]
  }

  const price = item.usPrice || item.finalPrice
  try {
    window.fa &&
      window.fa.ec(eventName, {
        eventLabel: routeName || '',
        items: productList,
        quantity: count,
        ...params
      })

    // ads
    window.gtag &&
      window.gtag('event', 'conversion', {
        send_to: 'AW-11138563565/Xyy9CPXGl5cYEO37o78p'
      })
    window.gtag &&
      window.gtag('event', 'conversion', {
        send_to: 'AW-11327363858/DyhNCNyp7vYYEJK2p5kq'
      })

    // GA4
    const categoryArr = item.categoriesNames?.split('/') || []
    window.gtag &&
      window.gtag('event', 'add_to_cart', {
        currency: 'USD',
        value: price * count,
        items: [
          {
            item_id: item.productsSkuId | item.productsId,
            item_name: item.productsName,
            // affiliation: "Google Merchandise Store",
            // coupon: "SUMMER_FUN",
            // discount: 2.22,
            // index: 0,
            // item_brand: "Google",
            item_category: categoryArr[0] || '',
            item_category2: categoryArr[1] || '',
            item_category3: categoryArr[2] || '',
            item_category4: categoryArr[3] || '',
            item_category5: categoryArr[4] || '',
            // item_list_id: "related_products",
            // item_list_name: "Related Products",
            // item_variant: "green",
            // location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
            price: price,
            quantity: count
          }
        ]
      })

    // fb
    window.fbq &&
      window.fbq(
        'track',
        'AddToCart',
        {
          currency: 'USD',
          value: price * count,
          content_ids: [item.productsId],
          content_name: item.productsName,
          content_type: routeName === 'List' ? 'product_group' : 'product',
          contents: [
            {
              id: item.productsId,
              quantity: count
            }
          ]
        },
        { eventID: fbe }
      )

    // pinterest
    window.pintrk &&
      window.pintrk('track', 'addtocart', {
        event_id: item.productsId + Date.now(),
        value: price * count,
        order_quantity: count,
        currency: 'USD'
      })

    // criteo
    pLoad.addToCart(item, count)

    window.ttq &&
      window.ttq.track('AddToCart', {
        contents: [
          {
            content_id: item.productsId, // string. ID of the product. Example: "1077218".
            content_type: 'product', // string. Either product or product_group.
            content_name: item.productsName // string. The name of the page or product. Example: "shirt".
          }
        ],
        value: price * count, // number. Value of the order or items sold. Example: 100.
        currency: 'USD' // string. The 4217 currency code. Example: "USD".
      })
  } catch (err) {
    console.error(`${eventName}: ${err}`)
  }
}

function removeFromCart(): void {}

/**
 * @description: 开始结算
 * @param {string} total
 * @param {any} list
 * @return {*}
 */
function beginCheckout(
  total: string | number,
  list: any[],
  coupon?: { coupon: string; discount: string; formatDiscount: string }
): void {
  const eventName = 'BeginCheckout'
  let productList: product[] = []
  let fb_ids = []
  let fb_contents = []
  let fb_num = 0
  let tt_contents = []
  // console.log('beginCheckout: ', list)
  list.map((item) => {
    productList.push({
      sku: item?.skuId || '', // 商品 sku
      status: item?.status || '', // 商品状态
      poa: item?.poa || '', // poa
      pid: item?.productsId || '', // 商品 pid*
      name: item?.productsName || '', // 商品名称*
      currency: 'USD', // 币种
      finalPrice: item?.finalPrice || '', // 最终价格
      discount: '', // 折扣百分比
      qty: item?.qty || '',
      categories: item?.categoriesNames || ''
    })
    fb_ids.push(item?.productsId)
    fb_contents.push({
      id: item?.productsId,
      name: item?.productsName,
      quantity: item?.qty
    })
    fb_num += item?.qty
    tt_contents.push({
      content_id: item?.productsId,
      content_type: 'product',
      content_name: item?.productsName
    })
  })
  try {
    setItem('winAtcPl', productList)
    window.fa &&
      window.fa.ec(eventName, {
        eventLabel: 'Cart',
        currency: 'USD',
        totalPrices: total,
        items: productList,
        couponCode: coupon?.coupon || ''
      })

    // ads
    window.gtag &&
      window.gtag('event', 'conversion', {
        send_to: 'AW-11138563565/ITnBCPjGl5cYEO37o78p',
        value: total,
        currency: 'USD'
      })
    window.gtag &&
      window.gtag('event', 'conversion', {
        send_to: 'AW-11327363858/zBp_CPvV7vYYEJK2p5kq'
      })

    // GA4
    window.gtag &&
      window.gtag('event', 'begin_checkout', {
        currency: 'USD',
        value: total,
        coupon: '',
        items: [
          // {
          //   item_id: 'SKU_12345',
          //   item_name: 'Stan and Friends Tee',
          //   affiliation: 'Google Merchandise Store',
          //   coupon: 'SUMMER_FUN',
          //   discount: 2.22,
          //   index: 0,
          //   item_brand: 'Google',
          //   item_category: 'Apparel',
          //   item_category2: 'Adult',
          //   item_category3: 'Shirts',
          //   item_category4: 'Crew',
          //   item_category5: 'Short sleeve',
          //   item_list_id: 'related_products',
          //   item_list_name: 'Related Products',
          //   item_variant: 'green',
          //   location_id: 'ChIJIQBpAG2ahYAR_6128GcTUEo',
          //   price: 9.99,
          //   quantity: 1
          // }
        ]
      })

    // fb
    window.fbq &&
      window.fbq(
        'track',
        'InitiateCheckout',
        {
          content_category: '',
          content_ids: fb_ids,
          contents: fb_contents,
          currency: 'USD',
          num_items: fb_num,
          value: total
        },
        { eventID: fbe }
      )

    window.ttq &&
      window.ttq.track('InitiateCheckout', {
        contents: tt_contents,
        value: 1, // number. Value of the order or items sold. Example: 100.
        currency: 'USD' // string. The 4217 currency code. Example: "USD".
      })
  } catch (err) {
    console.error(`${eventName}: ${err}`)
  }
}

/**
 * @description: 结算中
 * @param {string} total
 * @return {*}
 */
function checkoutProgress(
  total: string | number,
  coupon: { coupon: string; discount: string; formatDiscount: string },
  list: any[]
): void {
  const eventName = 'CheckoutProgress'
  let productList: product[] = []
  let tt_contents = []
  list.map((item) => {
    productList.push({
      sku: item?.skuId || '', // 商品 sku
      status: item?.status || '', // 商品状态
      poa: item?.poa || '', // poa
      pid: item?.productsId || '', // 商品 pid*
      name: item?.productsName || '', // 商品名称*
      currency: 'USD', // 币种
      finalPrice: item?.finalPrice || '', // 最终价格
      discount: '', // 折扣百分比
      qty: item?.qty || '',
      categories: item?.categoriesNames || ''
    })

    tt_contents.push({
      content_id: item?.productsId,
      content_type: 'product',
      content_name: item?.productsName
    })
  })
  // console.log('checkoutProgress: ', list)
  try {
    window.fa &&
      window.fa.ec(eventName, {
        eventLabel: 'Pay Now',
        orderId: '',
        currency: 'USD',
        totalPrices: total,
        items: productList,
        couponCode: coupon?.coupon || ''
      })

    window.ttq &&
      window.ttq.track('PlaceAnOrder', {
        contents: tt_contents,
        value: 1, // number. Value of the order or items sold. Example: 100.
        currency: 'USD' // string. The 4217 currency code. Example: "USD".
      })
  } catch (err) {
    console.error(`${eventName}: ${err}`)
  }
}

/**
 * @description: 支付信息
 * @param {string} type
 * @param {string} total
 * @return {*}
 */
function addPaymentInfo(type: string, total: string | number): void {
  const eventName = 'AddPaymentInfo'
  try {
    window.fa &&
      window.fa.ec(eventName, {
        type: type, // 支付类型
        currency: 'USD', // 币种
        totalPrices: total // 订单总价
      })

    // GA4
    window.gtag &&
      window.gtag('event', 'add_payment_info', {
        currency: 'USD',
        value: total,
        coupon: '',
        payment_type: type,
        items: [
          // {
          //   item_id: 'SKU_12345',
          //   item_name: 'Stan and Friends Tee',
          //   affiliation: 'Google Merchandise Store',
          //   coupon: 'SUMMER_FUN',
          //   discount: 2.22,
          //   index: 0,
          //   item_brand: 'Google',
          //   item_category: 'Apparel',
          //   item_category2: 'Adult',
          //   item_category3: 'Shirts',
          //   item_category4: 'Crew',
          //   item_category5: 'Short sleeve',
          //   item_list_id: 'related_products',
          //   item_list_name: 'Related Products',
          //   item_variant: 'green',
          //   location_id: 'ChIJIQBpAG2ahYAR_6128GcTUEo',
          //   price: 9.99,
          //   quantity: 1
          // }
        ]
      })

    // fb
    window.fbq &&
      window.fbq(
        'track',
        'AddPaymentInfo',
        {
          // content_category: '', // 页面或类别
          // content_ids: [''],
          // contents: [{}],
          currency: 'USD',
          value: total
        },
        { eventID: fbe }
      )

    window.ttq &&
      window.ttq.track('AddPaymentInfo', {
        contents: [],
        value: total, // number. Value of the order or items sold. Example: 100.
        currency: 'USD' // string. The 4217 currency code. Example: "USD".
      })
  } catch (err) {
    console.error(`${eventName}: ${err}`)
  }
}

/**
 * @description: 购买事件（支付成功）
 * @param {any} data
 * @return {*}
 */
function purchase(data: any): void {
  const orderId = data?.ordersId || ''
  const currency = 'USD'
  if (getItem('win_oid') != orderId) {
    const eventName = 'Purchase'
    let productList: product[] = []
    let fItems = []
    data?.ordersProducts?.map((item) => {
      productList.push({
        sku: item?.skuId || '', // 商品 sku
        status: item?.status || '', // 商品状态
        poa: item?.poa || '', // poa
        pid: item?.productsId || '', // 商品 pid*
        name: item?.productsName || '', // 商品名称*
        currency: 'USD', // 币种
        finalPrice: item?.finalPrice || '', // 最终价格
        discount: '', // 折扣百分比
        qty: item?.qty || '',
        categories: item?.categoriesNames || ''
      })

      fItems.push({
        id: item?.poa,
        quantity: Number(item?.qty),
        price: Number(item?.productsPrice)
      })
    })

    try {
      window.fa &&
        window.fa.ec(eventName, {
          eventLabel: orderId,
          orderId: orderId,
          sgOrderId: data?.sgOrderId || '',
          paymentMethod: data?.paymentMethodCode || '',
          currency,
          totalPrices: data?.otTotalValue || data?.otTotal || '',
          items: productList,
          couponCode: data?.couponCode || ''
        })
      setItem('win_oid', orderId)

      // ads
      window.enhanced_conversion_data = {
        email: data.customersEmailAddress || '',
        phone_number: '+001' + data.shippingAddress.telephone || ''
      }
      // window.gtag &&
      //   window.gtag('set', 'user_data', {
      //     email: window.enhanced_conversion_data.email,
      //     phone_number: window.enhanced_conversion_data.phone_number
      //   })
      // window.gtag &&
      //   window.gtag('event', 'conversion', {
      //     send_to: 'AW-11138563565/r5GFCPvGl5cYEO37o78p',
      //     value: data?.otTotalValue || data?.otTotal,
      //     currency,
      //     transaction_id: data?.sgOrderId,
      //   })
      // window.gtag &&
      //   window.gtag('event', 'conversion', {
      //     send_to: 'AW-11327363858/8_e8CK6ojOUYEJK2p5kq',
      //     value: data?.otTotalValue || data?.otTotal,
      //     currency,
      //     transaction_id: data?.sgOrderId,
      //   })
      const adsSendToCode = [
        'AW-11138563565/r5GFCPvGl5cYEO37o78p',
        'AW-11327363858/8_e8CK6ojOUYEJK2p5kq'
      ]
      adsSendToCode.map((code) => {
        window.gtag &&
          window.gtag('event', 'conversion', {
            send_to: code,
            value: data?.otTotalValue || data?.otTotal,
            currency,
            transaction_id: data?.sgOrderId
          })

        window.gtag &&
          window.gtag('event', 'purchase', {
            send_to: code,
            transaction_id: data?.sgOrderId,
            value: data?.otTotalValue || data?.otTotal,
            currency,
            discount: data?.totalDiscountValue || 0,
            aw_merchant_id: '354092802',
            aw_feed_country: 'US',
            aw_feed_language: 'EN',
            items: fItems
          })
      })

      // GA4
      window.gtag &&
        window.gtag('event', 'purchase', {
          transaction_id: data?.sgOrderId, // 交易的專屬 ID
          value: data?.otTotalValue || data?.otTotal, // 事件的金額
          tax: 0, // 交易相關的稅金
          shipping: 0, // 與交易相關聯的運費
          currency, // 與事件相關聯的項目貨幣
          coupon: '', // 與事件相關聯的優待券名稱/代碼
          items: [
            // 事件的項目
            {
              // item_id: "SKU_12345",
              // item_name: "Stan and Friends Tee",
              // affiliation: "Google Merchandise Store",
              // coupon: "SUMMER_FUN",
              // discount: 2.22,
              // index: 0,
              // item_brand: "Google",
              // item_category: "Apparel",
              // item_category2: "Adult",
              // item_category3: "Shirts",
              // item_category4: "Crew",
              // item_category5: "Short sleeve",
              // item_list_id: "related_products",
              // item_list_name: "Related Products",
              // item_variant: "green",
              // location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
              // price: 9.99,
              // quantity: 1
            }
          ]
        })

      // fb
      window.fbq &&
        window.fbq(
          'track',
          'Purchase',
          {
            currency,
            value: data?.otTotalValue || data?.otTotal
          },
          { eventID: fbe }
        )

      createShareASaleImg(data)

      // bing
      window.uetq = window.uetq || []
      // window.uetq.push('event', '', {
      //   revenue_value: data?.otTotalValue || data?.otTotal,
      //   currency
      // })
      window.uetq.push('event', 'purchase', {
        revenue_value: data?.otTotalValue || data?.otTotal,
        currency
      })

      // pinterest
      window.pintrk &&
        window.pintrk('track', 'checkout', {
          event_id: orderId,
          value: data?.otTotalValue || data?.otTotal,
          order_quantity: 1,
          currency,
          order_id: orderId
        })

      // goaffpro
      window.goaffproTrackConversion &&
        window.goaffproTrackConversion({
          number: orderId,
          total: data?.otTotalValue || data?.otTotal
        })

      pLoad.checkout(data)

      // injectScript(
      //   `https://auctera.gotrackier.com/pixel?av=654dc5c1695bbd222172527b&txn_id=${orderId}&PAYOUT=0&sale_amount=${
      //     data?.otTotalValue || data?.otTotal
      //   }`,
      //   null,
      //   false,
      //   null,
      //   'body',
      //   'iframe'
      // )

      window.ttq &&
        window.ttq.track('CompletePayment', {
          contents: [],
          value: data?.otTotalValue || data?.otTotal, // number. Value of the order or items sold. Example: 100.
          currency: 'USD' // string. The 4217 currency code. Example: "USD".
        })
    } catch (err) {
      console.error(`${eventName}: ${err}`)
    }
  }
}

function refund(): void {}

/**
 * @description: 搜索
 * @param {string} keyword
 * @param {number} resultNum
 * @return {*}
 */
function search(keyword: string, resultNum?: number): void {
  const eventName = 'Search'
  try {
    window.fa &&
      window.fa.en(eventName, {
        keyword,
        resultTotal: resultNum || 0
      })

    // GA4
    window.gtag &&
      window.gtag('event', 'search', {
        search_term: keyword
      })

    // fb
    window.fbq &&
      window.fbq(
        'track',
        'Search',
        {
          search_string: keyword,
          // content_ids: '',
          // content_type: '',
          // contents: '',
          currency: 'USD'
          // value: '',
        },
        { eventID: fbe }
      )

    // pinterest
    window.pintrk &&
      window.pintrk('track', 'search', {
        event_id: Date.now(),
        search_query: keyword
      })

    // tiktok
    window.ttq &&
      window.ttq.track('Search', {
        contents: [
          // {
          //   content_id: '',
          //   content_type: '',
          //   content_name: ''
          // }
        ],
        value: 1,
        currency: 'USD',
        query: keyword
      })
  } catch (err) {
    console.error(`${eventName}: ${err}`)
  }
}

function share(): void {}

/**
 * @description: 用户注册
 * @param {'Driect' | 'Google' | 'Facebook'} method
 * @param {string} email 用户邮箱
 * @return {*}
 */
function signUp(method: 'Driect' | 'Google' | 'Facebook', email: string): void {
  const eventName = 'SignUp'
  try {
    window.fa &&
      window.fa.en(eventName, {
        method
      })

    // GA4
    window.gtag &&
      window.gtag('config', window.threeTrackId?.ga4, {
        user_id: email
      })
    window.gtag &&
      window.gtag('event', 'sign_up', {
        method
      })

    // fb
    window.fbq &&
      window.fbq(
        'track',
        'Lead',
        {
          // content_name: '',
          // currency: 'USD',
          // status: '',
          // value: ''
        },
        { eventID: fbe }
      )
    window.fbq &&
      window.fbq(
        'track',
        'CompleteRegistration',
        {
          content_name: '',
          currency: 'USD',
          status: '',
          value: ''
        },
        { eventID: fbe }
      )

    // pinterest
    window.pintrk &&
      window.pintrk('track', 'signup', {
        event_id: Date.now()
      })

    window.ttq &&
      window.ttq.track('CompleteRegistration', {
        contents: [],
        value: 1, // number. Value of the order or items sold. Example: 100.
        currency: 'USD' // string. The 4217 currency code. Example: "USD".
      })
  } catch (err) {
    console.error(`${eventName}: ${err}`)
  }
}

/**
 * @description: 用户登录
 * @param {number} userId 用户唯一标识，目前传了用户邮箱
 * @return {*}
 */
function login(userId: number | string): void {
  const eventName = 'Login'
  try {
    window.fa &&
      window.fa.en(eventName, {
        userId
      })

    // GA 4
    window.gtag &&
      window.gtag('config', window.threeTrackId?.ga4, {
        user_id: userId
      })
    window.gtag &&
      window.gtag('event', 'login', {
        method: ''
      })
  } catch (err) {
    console.error(`${eventName}: ${err}`)
  }
}

/**
 * @description: 订阅
 * @param {string} email
 * @return {*}
 */
function subscribe(
  email: string,
  page: string,
  module: string,
  position: 'Auto' | 'Float' | 'Footer' | 'SaveAddress' | 'CreateOrder'
): void {
  const eventName = 'Subscribe'
  try {
    window.fa &&
      window.fa.en(eventName, {
        email,
        page,
        module,
        position
      })

    // ads
    window.gtag &&
      window.gtag('event', 'conversion', {
        send_to: 'AW-11138563565/BIrzCPLGl5cYEO37o78p'
      })
    window.gtag &&
      window.gtag('event', 'conversion', {
        send_to: 'AW-11327363858/Xj-jCP7V7vYYEJK2p5kq'
      })

    // GA 4
    window.gtag &&
      window.gtag('config', window.threeTrackId?.ga4, {
        user_id: email
      })
    window.gtag &&
      window.gtag('event', 'subscribe', {
        id: ''
      })

    // fb
    window.fbq &&
      window.fbq(
        'track',
        'Subscribe',
        {
          currency: 'USD',
          predicted_ltv: 1,
          value: 1
        },
        { eventID: fbe }
      )

    window.ttq &&
      window.ttq.track('Subscribe', {
        contents: [],
        value: 1, // number. Value of the order or items sold. Example: 100.
        currency: 'USD' // string. The 4217 currency code. Example: "USD".
      })
  } catch (err) {
    console.error(`${eventName}: ${err}`)
  }
}

/**
 * @description: 用户操作地址事件
 * @param {enum} type 操作类型
 * @param {any} addressInfo 地址数据
 * @return {*}
 */
function address(type: 'add' | 'select', addressInfo: any): void {
  const eventName = type === 'add' ? 'AddAddress' : 'SelectAddress'
  try {
    window.fa &&
      window.fa.en(eventName, {
        addressInfo,
        items: getItem('winAtcPl')
      })
    // GA 4
    window.gtag &&
      window.gtag('config', window.threeTrackId?.ga4, {
        user_id: addressInfo?.email
      })
  } catch (err) {
    console.error(`${eventName}: ${err}`)
  }
}

function setUserTag(value: string) {
  try {
    window.fa && window.fa?.setUserTag(value)
  } catch (error) {
    console.error('window.fa.setUserTag is not afunction')
  }
}

// meta pixel 差异事件
const meta = {
  viewContent: (info: any) => {
    let ids = [info?.productsId]
    let contents = [
      {
        id: info?.productsId,
        quantity: 1
      }
    ]

    try {
      window.fbq &&
        window.fbq(
          'track',
          'ViewContent',
          {
            content_ids: ids,
            content_category: info?.categoriesNames || '',
            content_name: info?.productsName,
            content_type: 'product',
            contents: contents,
            currency: 'USD',
            value: info?.usPrice || info.finalPrice
          },
          { eventID: fbe }
        )

      window.ttq &&
        window.ttq.track('ViewContent', {
          contents: [
            {
              content_id: info?.productsId, // string. ID of the product. Example: "1077218".
              content_type: 'product', // string. Either product or product_group.
              content_name: info?.productsName // string. The name of the page or product. Example: "shirt".
            }
          ],
          value: 1, // number. Value of the order or items sold. Example: 100.
          currency: 'USD' // string. The 4217 currency code. Example: "USD".
        })
    } catch (err) {
      console.error(`Meta ViewContent: ${err}`)
    }
  }
}

/**
 * @description: 动态插入 js
 * @param {string} src js文件src
 * @param {function} callback 加载完成后回调函数
 * @param {boolean} async 是否添加 async 属性
 * @param {boolean} id 是否添加 id 属性
 * @param {string} container 插入容器，如：head
 * @param {string} ele 插入元素，如：script
 * @return {*}
 */
function injectScript(
  src: string,
  callback?: (dom: HTMLElement) => void,
  async: boolean = false,
  id?: string,
  container: string = 'head',
  ele: string = 'script'
): void {
  if (id && document.getElementById(id)) {
    return
  }
  const head = document.getElementsByTagName(container)[0],
    script: any = document.createElement(ele)
  if (ele === 'script') {
    script.type = 'text/javascript'
    script.async = async
  } else if (ele === 'iframe') {
    script.scrolling = 'no'
    script.frameborder = 0
    script.width = '1'
    script.height = '1'
    script.style = 'border:none'
  }

  script.src = src
  script.onload = function () {
    callback && callback(script)
  }
  id ? script.setAttribute('id', id) : ''
  head.appendChild(script)
}

/**
 * @description: 指定页面加载统计的类
 * @return {*}
 */
class PageLoad {
  #currency = 'USD'
  #gdpr = 0
  #gdpr_pd = 0
  #us_privacy = ''
  #gdpr_consent = 'GDPR_CONSENT_874'
  #customerType = 'new'
  #clickdealerId = 'b5347c0b-3976-4c40-942d-26c2cbe0404d'
  #clickDealerName = 'HERNEST'
  #criteoId = '110559'

  constructor() {}

  /**
   * @description: 首页加载
   * @return {*}
   */
  home(): void {
    window.data_4743 = []
    window.data_4743.push({
      gdpr: this.#gdpr,
      gdpr_consent: this.#gdpr_consent,
      gdpr_pd: this.#gdpr_pd,
      us_privacy: this.#us_privacy,
      PageType: 'home'
    })
    injectScript(
      'https://cdn.preciso.net/aud/clientjs/ptag.js?4743',
      null,
      true,
      'DLFNPMMP1'
    )
    injectScript('https://pixel.adfyier.com/merchant.js', null, true, null)
    // clickdealer
    // injectScript(
    //   `//rtag.opivads.com/trk/v1.1/pixel.min.js?c=${
    //     this.#clickdealerId
    //   }&e=1&n=${
    //     this.#clickDealerName
    //   }&cb=${new Date().getTime()}&enc=${encodeURIComponent(
    //     window.location.href
    //   )}`,
    //   null,
    //   true
    // )

    // criteo
    window.criteo_q = window.criteo_q || []
    var deviceType = /iPad/.test(navigator.userAgent)
      ? 't'
      : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(
            navigator.userAgent
          )
        ? 'm'
        : 'd'
    window.criteo_q.push(
      { event: 'setAccount', account: this.#criteoId },
      {
        event: 'setEmail',
        email: '',
        hash_method: 'none'
      },
      { event: 'setSiteType', type: deviceType },
      { event: 'setZipcode', zipcode: '' },
      { event: 'viewHome' }
    )
  }

  /**
   * @description: 分类页统计
   * @param {string[]|number[]} productIdList 产品id列表，或用 | 分隔
   * @param {string|number} categoryIdOrName 分类 id 或分类名称，推荐统一传分类名称
   * @return {*}
   */
  category(
    productIdList: string[] | number[],
    categoryIdOrName: string | number
  ): void {
    window.data_4743 = []
    window.data_4743.push({
      Currency: this.#currency,
      CustomerType: this.#customerType,
      ProductId: productIdList,
      ProductCategoryList: categoryIdOrName,
      CouponCode: '',
      gdpr: this.#gdpr,
      gdpr_consent: this.#gdpr_consent,
      gdpr_pd: this.#gdpr_pd,
      us_privacy: this.#us_privacy,
      PageType: 'category'
    })
    injectScript(
      'https://cdn.preciso.net/aud/clientjs/ptag.js?4743',
      null,
      true,
      'DLFNPMMP1'
    )
    // clickdealer
    // injectScript(
    //   `//rtag.opivads.com/trk/v1.1/pixel.min.js?c=${
    //     this.#clickdealerId
    //   }&e=2&n=${
    //     this.#clickDealerName
    //   }&category=${categoryIdOrName}&cb=${new Date().getTime()}&enc=${encodeURIComponent(
    //     window.location.href
    //   )}`,
    //   null,
    //   true
    // )

    // criteo
    window.criteo_q = window.criteo_q || []
    var deviceType = /iPad/.test(navigator.userAgent)
      ? 't'
      : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(
            navigator.userAgent
          )
        ? 'm'
        : 'd'
    window.criteo_q.push(
      { event: 'setAccount', account: this.#criteoId },
      {
        event: 'setEmail',
        email: '',
        hash_method: 'none'
      },
      { event: 'setSiteType', type: deviceType },
      { event: 'setZipcode', zipcode: '' },
      {
        event: 'viewList',
        item: productIdList,
        category: categoryIdOrName,
        keywords: categoryIdOrName
      }
    )
  }

  /**
   * @description: 产品详情页加载
   * @param {string} productId 产品id
   * @param {string} categoryIdOrName 分类 id 或分类名称，推荐统一传分类名称
   * @return {*}
   */
  product(productId: string | number, categoryIdOrName: string | number): void {
    window.data_4743 = []
    window.data_4743.push({
      Currency: this.#currency,
      CustomerType: this.#customerType,
      ProductId: productId,
      ProductCategoryList: categoryIdOrName,
      CouponCode: '',
      gdpr: this.#gdpr,
      gdpr_consent: this.#gdpr_consent,
      gdpr_pd: this.#gdpr_pd,
      us_privacy: this.#us_privacy,
      PageType: 'product'
    })
    injectScript(
      'https://cdn.preciso.net/aud/clientjs/ptag.js?4743',
      null,
      true,
      'DLFNPMMP1'
    )
    injectScript('https://pixel.adfyier.com/merchant.js', null, true, null)
    // clickdealer
    // injectScript(
    //   `//rtag.opivads.com/trk/v1.1/pixel.min.js?c=${
    //     this.#clickdealerId
    //   }&e=3&n=${
    //     this.#clickDealerName
    //   }&category=${categoryIdOrName}&product=${productId}&cb=${new Date().getTime()}&enc=${encodeURIComponent(
    //     window.location.href
    //   )}`,
    //   null,
    //   true
    // )

    // criteo
    window.criteo_q = window.criteo_q || []
    var deviceType = /iPad/.test(navigator.userAgent)
      ? 't'
      : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(
            navigator.userAgent
          )
        ? 'm'
        : 'd'
    window.criteo_q.push(
      { event: 'setAccount', account: this.#criteoId },
      {
        event: 'setEmail',
        email: '',
        hash_method: 'none'
      },
      { event: 'setSiteType', type: deviceType },
      { event: 'setZipcode', zipcode: '' },
      { event: 'viewItem', item: productId || '' }
    )
  }

  /**
   * @description: 购物车加载
   * @param {string[] | number[]} productIdList 产品id列表，或用 | 分隔
   * @return {*}
   */
  cart(productIdList: string[] | number[]): void {
    window.data_4743 = []
    window.data_4743.push({
      Currency: this.#currency,
      CustomerType: this.#customerType,
      ProductId: productIdList,
      CouponCode: '',
      gdpr: this.#gdpr,
      gdpr_consent: this.#gdpr_consent,
      gdpr_pd: this.#gdpr_pd,
      us_privacy: this.#us_privacy,
      PageType: 'basket'
    })
    injectScript(
      'https://cdn.preciso.net/aud/clientjs/ptag.js?4743',
      null,
      true,
      'DLFNPMMP1'
    )
    // clickdealer
    // injectScript(
    //   `//rtag.opivads.com/trk/v1.1/pixel.min.js?c=${
    //     this.#clickdealerId
    //   }&e=4&n=${
    //     this.#clickDealerName
    //   }&products=${productIdList}&cb=${new Date().getTime()}&enc=${encodeURIComponent(
    //     window.location.href
    //   )}`,
    //   null,
    //   true
    // )

    // criteo
    window.criteo_q = window.criteo_q || []
    var deviceType = /iPad/.test(navigator.userAgent)
      ? 't'
      : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(
            navigator.userAgent
          )
        ? 'm'
        : 'd'
    window.criteo_q.push(
      { event: 'setAccount', account: this.#criteoId },
      {
        event: 'setEmail',
        email: '',
        hash_method: 'none'
      },
      { event: 'setSiteType', type: deviceType },
      { event: 'setZipcode', zipcode: '' },
      {
        event: 'viewBasket',
        item: productIdList?.map((item) => ({
          id: item.skuId,
          price: item.finalPrice,
          quantity: item.qty
        }))
      }
    )
  }

  /**
   * @description: 加购事件
   * @param {any} item 加购产品数据
   * @param {number} count 加购数量
   * @return {*}
   */
  addToCart(item: any, count: number): void {
    console.log('addToCart: ', item)
    // criteo
    window.criteo_q = window.criteo_q || []
    var deviceType = /iPad/.test(navigator.userAgent)
      ? 't'
      : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(
            navigator.userAgent
          )
        ? 'm'
        : 'd'
    window.criteo_q.push(
      { event: 'setAccount', account: this.#criteoId },
      {
        event: 'setEmail',
        email: '',
        hash_method: 'none'
      },
      { event: 'setSiteType', type: deviceType },
      { event: 'setZipcode', zipcode: '' },
      {
        event: 'addToCart',
        item: [
          {
            id: item.productsSkuId || '',
            price: item.usPrice || item.finalPrice || '',
            quantity: count
          }
        ]
      }
    )
  }

  /**
   * @description: 结算页加载
   * @param {object} data 订单信息
   * @return {*}
   */
  checkout(data: any): void {
    const productIdList = data?.ordersProducts?.map((item) => item.skuId)
    const orderId = data?.ordersId || ''
    const amount = data?.otTotalValue || data?.otTotal

    window.data_4743 = []
    window.data_4743.push({
      Currency: this.#currency,
      CustomerType: this.#customerType,
      ProductId: productIdList,
      CouponCode: '',
      gdpr: this.#gdpr,
      gdpr_consent: this.#gdpr_consent,
      gdpr_pd: this.#gdpr_pd,
      us_privacy: this.#us_privacy,
      OrderId: orderId,
      Amount: amount,
      PageType: 'checkout'
    })
    injectScript(
      'https://cdn.preciso.net/aud/clientjs/ptag.js?4743',
      null,
      true,
      'DLFNPMMP1'
    )
    // clickdealer
    // injectScript(
    //   `//rtag.opivads.com/trk/v1.1/pixel.min.js?c=${
    //     this.#clickdealerId
    //   }&e=5&n=${
    //     this.#clickDealerName
    //   }&purch_id=${orderId}&products=${productIdList}&amount=${amount}&cb=${new Date().getTime()}&enc=${encodeURIComponent(
    //     window.location.href
    //   )}`,
    //   null,
    //   true
    // )

    // criteo
    let criteoItem = []
    data?.ordersProducts?.map((item) => {
      criteoItem.push({
        id: item?.poa || '',
        price: item?.finalPrice || '',
        quantity: item?.qty || ''
      })
    })

    window.criteo_q = window.criteo_q || []
    var deviceType = /iPad/.test(navigator.userAgent)
      ? 't'
      : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(
            navigator.userAgent
          )
        ? 'm'
        : 'd'
    window.criteo_q.push(
      { event: 'setAccount', account: this.#criteoId },
      {
        event: 'setEmail',
        email: data.customersEmailAddress || '',
        hash_method: 'none'
      },
      { event: 'setSiteType', type: deviceType },
      { event: 'setZipcode', zipcode: data.shippingAddress.postcode || '' },
      {
        event: 'trackTransaction',
        id: orderId,
        item: criteoItem
      }
    )
  }
}

const pLoad = new PageLoad()

export default {
  custom,
  viewSection,
  ViewSectionForExposure,
  viewItem,
  viewItemList,
  addToCart,
  removeFromCart,
  beginCheckout,
  checkoutProgress,
  purchase,
  refund,
  search,
  share,
  signUp,
  login,
  subscribe,
  address,
  addToWishlist, // 暂无这个功能
  addPaymentInfo, // 暂时没埋点
  setUserTag,
  meta,
  pLoad
}
